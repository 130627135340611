<template>
    <div>
        <card>
            <template #header>
                <h5 class="mb-0">{{ __('maintainers.overview.title') }}</h5>
                <jet-button
                    v-if="hasAnyPermission('maintainer.create')"
                    @click.prevent="createMaintainer"
                    >{{ __('maintainers.overview.action.add') }}
                </jet-button>
            </template>
            <total-table-items
                :text="
                    __('maintainers.overview.results.count', {
                        total: maintainers?.meta.total || 0,
                    })
                "
            />
            <data-table
                :actions="actions"
                :columns="fields"
                :default-sorting="{ column: 'sla', asc: true }"
                :items="maintainers?.data || []"
                :no-items-view="noItemsView"
                :sticky-header="{ top: '123px' }"
                action-classes="align-top"
                sort-query-param="maintainers_sort"
                @update:sorter-value="updateSorting"
            >
                <template #name="{ item }">
                    <td class="align-top">
                        <div class="text-break">{{ item.name }}</div>
                        <div
                            v-if="item.sla_expires_soon || item.sla_expired"
                            class="d-flex align-items-center text-danger text-nowrap"
                            style="gap: 4px; font-size: 13px"
                        >
                            <exclamation-mark type="filled" />
                            {{ getExpirationInfo(item) }}
                        </div>
                        <jet-button
                            v-c-tooltip="{
                                content: 'API connection coming soon',
                                popperOptions: {
                                    positionFixed: true,
                                },
                            }"
                            :disabled="true"
                            class="mt-2"
                            color="secondary"
                            style="min-width: 0"
                        >
                            Integrate
                        </jet-button>
                    </td>
                </template>

                <template #contact="{ item }">
                    <td class="align-top">
                        <div v-if="item.email || item.description">
                            <div v-if="item.email">
                                <email-link :email="item.email" />
                            </div>
                            <div v-if="item.phone">
                                <phone-link :number="item.phone" />
                            </div>
                            <div v-if="item.description">
                                {{ item.description }}
                            </div>
                        </div>
                        <div v-else>
                            {{ __('general.not_available') }}
                        </div>
                    </td>
                </template>

                <template #sla="{ item }">
                    <td class="align-middle">
                        <service-level-agreement-info
                            :show-expiration-warning="
                                item.sla_expires_soon || item.sla_expired
                            "
                            :sla="item.sla"
                        />
                    </td>
                </template>

                <template #assets_count="{ item }">
                    <td class="align-top">
                        <div>{{ item.assets_count || 0 }}</div>
                    </td>
                </template>

                <template #locations_count="{ item }">
                    <td class="align-top">
                        <div>{{ item.locations_count || 0 }}</div>
                    </td>
                </template>
            </data-table>
            <template v-if="hasMultiplePages(maintainers)" #footer>
                <pagination
                    :only="['maintainers']"
                    :paginator="maintainers"
                    class="mt-2"
                />
            </template>
        </card>
        <update-or-create-maintainer-form-modal />
    </div>
</template>
<script>
import JetButton from '@/Jetstream/Button.vue';
import Pagination from '@/Components/Pagination.vue';
import TotalTableItems from '@/Components/TotalTableItems.vue';
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import EmailLink from '@/Components/EmailLink.vue';
import UpdateOrCreateMaintainerFormModal from '@/Pages/Stakeholders/Maintainers/UpdateOrCreateMaintainerFormModal.vue';
import { eventBus, events } from '@/eventBus.js';
import PhoneLink from '@/Components/PhoneLink.vue';
import ServiceLevelAgreementForm from '@/Pages/Stakeholders/Maintainers/ServiceLevelAgreementForm.vue';
import ServiceLevelAgreementInfo from '@/Pages/Stakeholders/Maintainers/ServiceLevelAgreementInfo.vue';
import DeleteStakeholderModal from '@/Pages/Stakeholders/DeleteStakeholderModal.vue';
import { DateTime } from 'luxon';
import DataTable from '@/Components/DataTable.vue';
import Card from '@/Components/Card.vue';
import ExclamationMark from '../../../../icons/ExclamationMark.vue';
import Badge from '@/Components/Badge.vue';
import { hasMultiplePages } from '@/Utils/HasMultiplePages';

export default {
    name: 'MaintainersOverview',
    components: {
        Badge,
        ExclamationMark,
        Card,
        DataTable,
        DeleteStakeholderModal,
        ServiceLevelAgreementInfo,
        ServiceLevelAgreementForm,
        PhoneLink,
        UpdateOrCreateMaintainerFormModal,
        EmailLink,
        CustomCardHeader,
        TotalTableItems,
        Pagination,
        JetButton,
    },
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: this.__('maintainers.overview.cell.name'),
                },
                {
                    key: 'sla',
                    label: this.__('maintainers.overview.cell.sla'),
                    _style: 'white-space: nowrap;width:90px',
                },
                {
                    key: 'contact',
                    label: this.__('maintainers.overview.cell.contact'),
                    _style: 'white-space: nowrap;width:300px;min-width:300px',
                    sorter: false,
                },
                {
                    key: 'assets_count',
                    label: this.__('maintainers.overview.cell.assets'),
                    _style: 'white-space: nowrap;width:90px;',
                    sorter: false,
                },
                {
                    key: 'locations_count',
                    label: this.__('maintainers.overview.cell.locations'),
                    _style: 'white-space: nowrap;width:90px;',
                    sorter: false,
                },
            ],
            actions: [
                {
                    permissions: ['maintainer.update'],
                    tooltip: this.__('general.action.edit'),
                    type: 'primary',
                    click: this.editMaintainer,
                    icon: 'cil-pencil',
                },
                {
                    permissions: ['maintainer.delete'],
                    tooltip: this.__('general.action.delete'),
                    type: 'danger',
                    click: this.deleteMaintainer,
                    icon: 'cil-trash',
                },
            ],
            noItemsView: {
                noResults: this.__('maintainers.overview.filters.empty'),
                noItems: this.__('maintainers.overview.results.empty'),
            },
        };
    },
    computed: {
        maintainers() {
            return this.$page.props.maintainers;
        },
    },
    mounted() {
        let [column, order] = this.$page.props.maintainers_sort.split('.');
        this.sortOrder = {
            column,
            asc: order === 'asc',
        };
    },
    methods: {
        hasMultiplePages,
        createMaintainer() {
            eventBus.$emit(events.openCreateMaintainerModal);
        },
        editMaintainer(item) {
            eventBus.$emit(events.openEditMaintainerModal, {
                maintainer: item,
            });
        },
        deleteMaintainer(item) {
            eventBus.$emit(events.openConfirmDeleteStakeholderModal, {
                stakeholder: item,
                type: 'maintainer',
            });
        },
        updateSorting(event) {
            let sortOrder = event.asc ? 'asc' : 'desc';
            this.$inertia.visit(
                this.route(this.route().current(), {
                    maintainers_sort: `${event.column}.${sortOrder}`,
                }),
                {
                    preserveState: true,
                    preserveScroll: true,
                    only: ['maintainers'],
                },
            );
        },
        getExpirationInfo(maintainer) {
            if (!maintainer.sla || !maintainer.sla.agreement) {
                return null;
            }
            let date = DateTime.fromFormat(
                maintainer.sla.agreement.endDate,
                'y-MM-dd',
            );

            return date.diffNow('days').days < 0
                ? this.__('maintainers.overview.sla_expired')
                : this.__('maintainers.overview.sla_expires_soon');
        },
    },
};
</script>
