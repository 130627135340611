<template>
    <div :class="classes" class="d-flex" style="gap: 3px">
        <div
            :class="{ 'd-sm-none': !$slots['visible-filter'] }"
            class="d-flex justify-content-between align-items-center"
        >
            <slot name="visible-filter" />
            <button
                v-if="$slots.default"
                class="btn-text d-flex align-items-center d-sm-none"
                style="gap: 5px; min-height: 44px"
                @click.stop="isOpen = !isOpen"
            >
                <icon
                    class="flex-shrink-0"
                    icon="cil-list-filter"
                    style="position: relative; top: -1px"
                />
                <span>{{ __('general.table.filter.label') }}</span>
            </button>
            <button
                v-else
                :class="{ 'opacity-100': isProcessing }"
                :disabled="!active"
                class="btn btn-text text-center mt-1 px-3 d-sm-none"
                @click="$emit('clear')"
            >
                <spinner v-if="isProcessing" />
                <span v-else>{{ __('general.action.clear') }}</span>
            </button>
        </div>
        <collapse :show="isOpen" class="d-sm-flex">
            <div
                :class="classes"
                class="d-flex"
                style="gap: 3px; min-height: 36px"
            >
                <slot />
                <div class="d-flex justify-content-between align-items-center">
                    <slot name="filter-end">
                        <button
                            :class="{ 'opacity-100': isProcessing }"
                            :disabled="!active"
                            class="btn btn-text text-center mt-1 px-3"
                            @click="$emit('clear')"
                        >
                            <spinner v-if="isProcessing" />
                            <span v-else>{{ __('general.action.clear') }}</span>
                        </button>
                    </slot>
                </div>
            </div>
        </collapse>
    </div>
</template>
<script>
import JetButton from '@/Jetstream/Button.vue';
import Collapse from '@/Components/Collapse.vue';
import Spinner from '@/Components/Spinner.vue';

export default {
    name: 'CollapsableFilterBar',
    components: { Spinner, Collapse, JetButton },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        isProcessing: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
            width: document.body.clientWidth,
        };
    },
    computed: {
        classes() {
            if (this.width < 576) {
                return 'flex-column';
            }

            return ['flex-row', 'align-items-center'];
        },
    },
    created() {
        window.addEventListener('resize', this.updateSize);
    },
    destroyed() {
        window.removeEventListener('resize', this.updateSize);
    },
    methods: {
        updateSize() {
            this.width = document.body.clientWidth;

            if (this.width > 575) {
                this.isOpen = true;
            }
        },
    },
};
</script>
