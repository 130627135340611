<template>
    <div ref="container">
        <div
            :class="{
                'form-group': isFormField,
                row: isFormField && horizontal,
                'mb-3': isFormField,
            }"
            :role="isFormField ? 'group' : ''"
        >
            <label
                v-if="isFormField && label"
                :class="[labelClasses, { required }]"
                >{{ label }}</label
            >
            <div :class="inputClasses">
                <div
                    :class="{
                        'c-show': show,
                        'form-control': isFormField,
                        'is-invalid': invalidFeedback,
                        disabled: disabled,
                    }"
                    class="multi-select multi-select-multiple"
                    tabindex="0"
                    @click="toggleVisibility"
                    @keydown.esc="show = false"
                >
                    <div
                        class="multi-select-selection d-flex justify-content-between align-items-center"
                        style="min-height: 2rem; gap: 5px"
                    >
                        <div
                            v-if="isFormField"
                            :style="
                                selectedOptions.length
                                    ? 'line-height: 0'
                                    : 'line-height: 1rem'
                            "
                            style="max-width: 100%"
                        >
                            <span
                                v-for="tag in selectedOptions"
                                :key="tag?.value"
                                class="multi-select-tag text-truncate"
                                >{{ tag.label }}
                                <icon
                                    class="flex-shrink-0 d-inline-block"
                                    icon="cil-x-circle"
                                    @click.native.stop="remove(tag)"
                            /></span>
                            <div
                                v-if="!selectedOptions.length"
                                class="text-truncate"
                            >
                                {{ placeholder }}
                            </div>
                        </div>
                        <span v-else class="text-truncate">{{ label }}</span>
                        <span
                            v-if="selected.length > 0 && !isFormField"
                            class="badge badge-pill bg-primary"
                            style="min-width: 14px"
                            >{{ selected.length }}</span
                        >
                    </div>
                    <div
                        :style="styles"
                        class="multi-select-options"
                        @click.stop=""
                    >
                        <div
                            v-if="showSearchbar"
                            :class="{ 'sh-b': isTopShadowVisible }"
                            class="dropdown-list-filter p-2"
                        >
                            <div
                                class="input-group filter-search w-100"
                                style="min-width: 200px"
                            >
                                <input
                                    ref="listFilter"
                                    v-model="searchString"
                                    autofocus
                                    class="form-control"
                                    @focus="$refs.listFilter?.select()"
                                    @input="$nextTick(() => onScroll())"
                                />
                                <div class="input-group-text">
                                    <spinner v-if="isProcessing" />
                                    <search v-else />
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="
                                availableOptionsCount > 2 && showSelectAllButton
                            "
                            :class="{
                                'multi-selected':
                                    selected.length === options.length,
                            }"
                            class="multi-select-option text-nowrap"
                            tabindex="0"
                            @click.stop="toggleAll()"
                        >
                            <slot name="label"
                                >{{ __('general.action.all') }}
                            </slot>
                        </div>
                        <div
                            ref="listWrapper"
                            class="p-2"
                            style="overflow: auto; max-height: 300px"
                            @scroll="onScroll"
                        >
                            <div
                                v-for="group in groupedOptions"
                                :key="group.group"
                            >
                                <div
                                    v-if="group.group"
                                    :class="{
                                        'd-none':
                                            !groupResultsAreVisible(group),
                                    }"
                                    class="group-label"
                                    style="padding: 0px 10px"
                                >
                                    <small class="text-muted text-uppercase">{{
                                        group.group
                                    }}</small>
                                </div>
                                <div
                                    v-for="option in group.data"
                                    :key="option.value"
                                    :class="{
                                        'multi-selected': selected.includes(
                                            option.value,
                                        ),
                                        'd-none': !isOptionVisible(option),
                                    }"
                                    class="multi-select-option text-nowrap"
                                    tabindex="0"
                                    @click.stop="toggleOption(option.value)"
                                >
                                    <slot
                                        name="label"
                                        v-bind:option="option"
                                        v-bind:show="show"
                                    >
                                        <span class="text-primary">{{
                                            option.label
                                        }}</span>
                                    </slot>
                                </div>
                            </div>
                        </div>
                        <div
                            :class="[
                                {
                                    'sh-t': isBottomShadowVisible,
                                    'py-1': actionsVisible,
                                },
                                footerClasses,
                            ]"
                            class="d-flex justify-content-between align-items-center dropdown-actions px-2"
                            style="position: sticky; bottom: 0"
                        >
                            <div>
                                <slot
                                    :hide-element="hideElement"
                                    :show="show"
                                    name="footer"
                                />
                            </div>
                            <div
                                v-if="
                                    availableOptionsCount > 2 &&
                                    selected.length > 0
                                "
                            >
                                <button
                                    class="btn btn-link"
                                    style="min-width: 0"
                                    @click="deselectAll"
                                >
                                    {{ __('general.action.clear') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!!invalidFeedback" class="invalid-feedback">
                    {{ invalidFeedback }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Search from '@/../icons/Search.vue';

import Icon from '@/Components/Icon.vue';
import { onClickOutside } from '@vueuse/core';

export default {
    components: { Icon, Search },
    emits: ['update:modelValue'],
    props: {
        label: String,
        options: Array,
        modelValue: [String, Array],
        placeholder: String,
        isFormField: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showSelectAllButton: {
            type: Boolean,
            default: true,
        },
        default: {
            type: Array,
            default: () => [],
        },
        showSearchbar: {
            type: Boolean,
            default: false,
        },
        isProcessing: {
            type: Boolean,
            default: false,
        },
        styles: {
            type: Object,
            default: () => ({
                width: 'auto',
                'min-width': '100%',
            }),
        },
        invalidFeedback: String,
        horizontal: {
            type: Boolean,
            default: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        footerClasses: [String, Array],
    },
    mounted() {
        onClickOutside(this.$refs.container, this.hideElement);
    },

    data() {
        return {
            show: false,
            selected: [],
            searchString: '',
            isTopShadowVisible: false,
            isBottomShadowVisible: false,
        };
    },
    computed: {
        availableOptionsCount() {
            return this.groupedOptions.reduce(
                (sum, group) => sum + group.data.length,
                0,
            );
        },
        groupedOptions() {
            if (this.options[0]?.hasOwnProperty('data')) {
                return this.options;
            }
            return [{ group: null, data: this.options }];
        },
        selectedOptions() {
            return (
                this.modelValue?.map((value) =>
                    this.options.find((option) => option.value === value),
                ) || []
            );
        },
        labelClasses() {
            if (!this.isFormField || !this.horizontal) {
                return;
            }
            return [this.horizontal.label || 'col-md-3'];
        },
        inputClasses() {
            if (!this.isFormField || !this.horizontal) {
                return;
            }
            return this.horizontal.input || 'col-md-9';
        },
        actionsVisible() {
            return this.availableOptionsCount > 2 && this.selected.length > 0;
        },
    },
    watch: {
        modelValue(value) {
            this.selected = value;
        },
        show(value) {
            this.searchString = '';

            if (value) {
                this.$nextTick(() => {
                    this.$refs.listFilter?.focus();
                    this.$refs.listWrapper.scrollTop = 0;
                    this.onScroll();
                });
            }
        },
    },
    methods: {
        toggleVisibility() {
            if (this.disabled) {
                this.show = false;
            } else {
                this.show = !this.show;
            }
        },
        toggleAll() {
            let selection = [];
            if (this.selected.length !== this.options.length) {
                selection = this.options.map((option) => option.value);
            }

            this.$emit('update:modelValue', selection);
        },
        toggleOption(value) {
            let selection = [...this.selected];

            let idx = selection.indexOf(value);
            if (idx !== -1) {
                selection.splice(idx, 1);
            } else {
                selection.push(value);
            }
            this.$emit('update:modelValue', selection);
        },
        hideElement() {
            this.show = false;
        },
        deselectAll() {
            this.$emit('update:modelValue', this.default);
            this.searchString = '';
            this.hideElement();
        },
        onScroll() {
            const scrollTop = this.$refs.listWrapper.scrollTop;
            const elHeight = this.$refs.listWrapper.offsetHeight;
            const scrollHeight = this.$refs.listWrapper.scrollHeight;

            this.isTopShadowVisible = scrollTop > 0;
            this.isBottomShadowVisible =
                scrollHeight - elHeight - scrollTop > 0;
        },
        groupResultsAreVisible(group) {
            if (!this.searchString) {
                return true;
            }

            return group.data.some(this.isOptionVisible);
        },
        isOptionVisible(option) {
            return (option.searchableContent || option.label)
                .toLowerCase()
                .includes(this.searchString.toLowerCase());
        },

        remove(tag, event) {
            let value = this.modelValue.filter((value) => value !== tag.value);
            this.$emit('update:modelValue', value);
        },
    },
};
</script>
<style scoped>
.group-label {
    white-space: nowrap;
}
</style>
