import { camelCase } from 'lodash';

export default {
    methods: {
        hasFeature: function (requiredFeatures) {
            if (!Array.isArray(requiredFeatures)) {
                requiredFeatures = [requiredFeatures];
            }

            const availableFeatures = this.$page.props.auth?.user.features;

            if (!availableFeatures) {
                return false;
            }

            return requiredFeatures.every((feature) => {
                feature = camelCase(feature);

                return (
                    availableFeatures.hasOwnProperty(feature) &&
                    availableFeatures[feature]
                );
            });
        },
    },
};
