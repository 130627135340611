<template>
    <div
        v-if="paginator && numberOfPages > 1"
        class="d-flex justify-content-end"
    >
        <CPagination
            :active-page="currentPage"
            :doubleArrows="false"
            :pages="numberOfPages"
            @update:activePage="updateActivePage"
        >
            <template #next-button>
                {{ __('pagination.next') }}
            </template>
            <template #previous-button>
                {{ __('pagination.previous') }}
            </template>
        </CPagination>
    </div>
</template>
<script>
export default {
    props: {
        paginator: Object,
        query: Object,
        only: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        numberOfPages() {
            let { total, per_page } = this.paginator.meta;
            return Math.ceil(total / per_page);
        },
        currentPage() {
            return this.paginator.meta.current_page;
        },
        sort() {
            const sort = this.query?.sort;
            if (sort) {
                const sortDirection = sort.asc ? 'asc' : 'desc';
                return `${sort.column}.${sortDirection}`;
            }

            return undefined;
        },
    },

    methods: {
        updateActivePage(page) {
            const query = Object.assign({}, this.query, {
                sort: this.sort,
            });

            let url = this.paginator.meta.links.find(
                (link) => link.label === page.toString()
            ).url;

            this.$inertia.get(url, query, {
                preserveState: true,
                preserveScroll: true,
                only: this.only,
            });
        },
    },
};
</script>
