import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

export const useSidebarStore = defineStore('sidebar', () => {
    const visible = useLocalStorage('sidebar.visible', true);
    const unfoldable = useLocalStorage('sidebar.unfoldable', false);

    const toggleVisible = (value) => {
        visible.value = value !== undefined ? value : !visible.value;
    };

    const toggleUnfoldable = () => {
        unfoldable.value = !unfoldable.value;
    };

    const close = () => {
        visible.value = false;
    };

    return { visible, unfoldable, toggleVisible, toggleUnfoldable, close };
});
