<template>
    <div>
        <div class="d-flex flex-nowrap swimlane-container">
            <div
                v-for="(lane, index) in swimlanes"
                class="swimlane-wrapper d-flex col-sm-6 col-md-4 col-xl-3"
            >
                <div class="swimlane">
                    <header class="swimlane-header">
                        {{ lane.status.label }}
                        <span>({{ getWorkOrderCountForLane(lane) }})</span>
                    </header>
                    <div class="swimlane-body">
                        <div
                            v-if="showEmptyState && index === 0"
                            class="d-flex pt-4 pb-4 text-center"
                        >
                            <b>{{ __('workorder.overview.board.empty') }}</b>
                        </div>
                        <div v-else>
                            <work-order-card
                                v-for="workOrder in getWorkOrdersForStatus(
                                    lane.status.value,
                                )"
                                :key="`${workOrder.id}-${workOrder.updatedAt}`"
                                :ref="`wo-${workOrder.id}`"
                                :active="
                                    highlightedWorkOrderId === workOrder.id
                                "
                                :is-loading="isWorkOrderProcessing(workOrder)"
                                :work-order="workOrder"
                                @delete="requestWorkOrderDeletionConfirmation"
                                @click.native="loadDetails(workOrder)"
                                @move-to="moveToStatus"
                            />
                        </div>
                        <div
                            :ref="'landmark-' + index"
                            :data-status="lane.status.value"
                            class="d-flex p-2 justify-content-center"
                        >
                            <spinner v-if="isLoading(lane.status.value)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <confirm-work-order-deletion-modal />
    </div>
</template>
<script>
import WorkOrderCard from '@/Pages/WorkOrders/WorkOrderCard.vue';
import ConfirmWorkOrderDeletionModal from '@/Pages/WorkOrders/Modals/ConfirmWorkOrderDeletionModal.vue';
import { pick } from 'lodash';
import { eventBus, events } from '@/eventBus.js';

export default {
    name: 'work-order-board',
    components: { ConfirmWorkOrderDeletionModal, WorkOrderCard },
    props: {
        selectedWorkOrder: {},
        swimlanes: {},
        workOrdersUrl: {},
    },
    data() {
        return {
            highlightedWorkOrderId: null,
            processingWorkOrderIds: [],
            observer: null,
            workOrders: {},
            loading: [],
        };
    },
    watch: {
        workOrdersUrl(newValue) {
            this.loadAllWorkOrders();
        },
        highlightedWorkOrderId: {
            handler: function (newValue) {
                if (!newValue) {
                    return;
                }

                this.$nextTick(() => {
                    this.$refs[
                        'wo-' + newValue
                    ]?.[0]?.$el.scrollIntoViewIfNeeded();
                });
            },
            immediate: true,
        },
    },
    computed: {
        totalWorkOrderCount() {
            return Object.keys(this.workOrders).reduce((sum, key) => {
                return sum + this.workOrders[key].meta?.total || 0;
            }, 0);
        },
        showEmptyState() {
            return !this.loading.length && !this.totalWorkOrderCount;
        },
    },
    methods: {
        getWorkOrdersForStatus(status) {
            return this.workOrders[status]?.data || [];
        },
        moveToStatus(event) {
            const { workOrder, status } = event;

            if (this.isWorkOrderProcessing(workOrder)) {
                return;
            }

            this.setWorkOrderProcessing(workOrder.id, true);

            this.$inertia.put(
                this.route('workorders.status.update', workOrder),
                {
                    status,
                },
                {
                    preserveState: true,
                    onSuccess: () => {
                        eventBus.$emit(events.workOrderStatusUpdated, {
                            workOrderId: workOrder.id,
                            status,
                        });

                        this.assignInitialWorkOrders();
                        this.highlightedWorkOrderId = workOrder.id;
                    },
                    onFinish: () => {
                        this.setWorkOrderProcessing(workOrder.id, false);
                    },
                },
            );
        },
        isWorkOrderProcessing(workOrder) {
            let id = workOrder?.id || workOrder;

            if (!id) {
                return false;
            }

            return this.processingWorkOrderIds.includes(id);
        },
        setWorkOrderProcessing(workOrder, isLoading) {
            const id = workOrder?.id || workOrder;

            if (isLoading) {
                this.processingWorkOrderIds.push(id);
            } else {
                this.processingWorkOrderIds =
                    this.processingWorkOrderIds.filter((el) => el !== id);
            }
        },
        requestWorkOrderDeletionConfirmation(workOrder) {
            eventBus.$emit(events.openDeleteWorkOrderModal, {
                workOrder,
                onDeleting: (workOrder) => {
                    this.setWorkOrderProcessing(workOrder, true);
                },
                onDeleted: (workOrder) => {
                    this.setWorkOrderProcessing(workOrder, false);
                },
            });
        },
        loadDetails(workOrder) {
            this.highlightedWorkOrderId = workOrder.id;

            // Update URL
            let url = new URL(window.location.href);
            url.searchParams.set('workorder', workOrder.id);
            window.history.pushState({}, '', url.toString());

            eventBus.$emit(events.openWorkOrderDetailsModal, {
                workOrderId: workOrder.id,
            });
        },
        loadAllWorkOrders() {
            const statuses = this.swimlanes.map((lane) => lane.status.value);
            this.loadWorkOrders(statuses);
        },
        loadWorkOrders(statuses) {
            // Filter already loading statuses
            statuses = statuses
                .filter((status) => !this.loading.includes(status))
                .unique();

            // Prevent duplicate loading same resource
            if (!statuses.length) {
                return;
            }

            // Get URL from for all states or for next page of specific lane
            const url =
                statuses.length > 1
                    ? this.workOrdersUrl
                    : this.workOrders[statuses[0]]?.links.next;

            if (!url) {
                return;
            }

            this.setIsLoading(statuses, true);

            this.$inertia.get(
                url,
                {},
                {
                    replace: statuses.length > 1,
                    preserveState: true,
                    preserveScroll: true,
                    only: statuses,
                    onSuccess: (data) => {
                        let newData = pick(data.props, statuses);
                        Object.keys(newData).forEach((key) => {
                            let data = newData[key];
                            if (data.meta.current_page > 1) {
                                newData[key].data = [
                                    ...this.workOrders[key].data,
                                    ...newData[key].data,
                                ];
                            }
                        });

                        this.workOrders = Object.assign(
                            this.workOrders,
                            newData,
                        );
                    },
                    onFinish: () => {
                        // Remove page query param from URL
                        let url = new URL(window.location.href);
                        url.searchParams.delete('page');
                        window.history.replaceState({}, '', url.toString());
                        this.setIsLoading(statuses, false);
                    },
                },
            );
        },
        isLoading(status) {
            return this.loading.includes(status);
        },
        setIsLoading(statuses, isLoading) {
            let loadingStatuses = [...this.loading, ...statuses].unique();

            if (!isLoading) {
                loadingStatuses = loadingStatuses.filter(
                    (el) => !statuses.includes(el),
                );
            }

            this.loading = loadingStatuses;
        },
        getWorkOrderCountForLane(lane) {
            return this.workOrders[lane.status.value]?.meta?.total || 0;
        },
        assignInitialWorkOrders() {
            this.workOrders = Object.assign(
                this.workOrders,
                pick(
                    this.$page.props,
                    this.swimlanes.map((lane) => lane.status.value),
                ),
            );
        },
        setupSwimlaneObserver() {
            this.observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (!entry.isIntersecting) {
                            return;
                        }

                        const { status } = entry.target.dataset;
                        this.loadWorkOrders([status]);
                    });
                },
                {
                    root: null,
                    threshold: 0.3,
                },
            );
        },
        observeSwimlanes() {
            Object.keys(this.$refs).forEach((key) => {
                const el = this.$refs[key][0];
                if (!el) {
                    return;
                }

                this.observer.observe(el);
            });
        },
    },
    created() {
        this.setupSwimlaneObserver();
    },
    mounted() {
        this.assignInitialWorkOrders();
        this.observeSwimlanes();
    },
    beforeUnmount() {
        eventBus.$off(events.workOrderUpdated);
    },
    beforeDestroy() {
        this.observer.disconnect();
    },
};
</script>
