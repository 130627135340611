<template>
    <div class="position-relative table-responsive">
        <table class="table table-bordered mt-3">
            <thead>
            <tr>
                <th></th>
                <th style="vertical-align: middle">
                    For Corrective Maintenance
                </th>
                <th style="vertical-align: middle">
                    For Preventative Maintenance
                </th>
                <th style="vertical-align: middle">Total</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th style="vertical-align: middle">
                    <div>
                        Status Changes to Inoperable&nbsp;&nbsp;<info-icon
                        tooltip="The number of status changes to inoperable during the selected timeframe."
                    />
                    </div>
                </th>
                <td
                    v-if="!reportAvailable"
                    class="bg-gray-light"
                    colspan="3"
                    rowspan="3"
                >
                    <div class="text-center my-5">
                        <h2>
                            No Report available
                            <svg
                                class="text-danger mb-2 c-icon c-icon-custom-size"
                                role="img"
                                viewBox="0 0 512 512"
                                width="30"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    class="ci-primary"
                                    d="M425.706,86.294A240,240,0,0,0,86.294,425.705,240,240,0,0,0,425.706,86.294ZM256,48A207.1,207.1,0,0,1,391.528,98.345L98.345,391.528A207.1,207.1,0,0,1,48,256C48,141.309,141.309,48,256,48Zm0,416a207.084,207.084,0,0,1-134.986-49.887l293.1-293.1A207.084,207.084,0,0,1,464,256C464,370.691,370.691,464,256,464Z"
                                    fill="var(--ci-primary-color, currentColor)"
                                ></path>
                            </svg>
                        </h2>
                    </div>
                </td>
                <td
                    v-if="reportAvailable"
                    class="bg-gray-light"
                    style="vertical-align: middle"
                >
                    <div class="text-nowrap">
                        {{
                            report.statusChangeToInoperableCount
                                ?.correctiveMaintenance || 0
                        }}
                    </div>
                </td>
                <td
                    v-if="reportAvailable"
                    class="bg-gray-light"
                    style="vertical-align: middle"
                >
                    <div class="text-nowrap">
                        {{
                            report.statusChangeToInoperableCount
                                ?.preventativeMaintenance || 0
                        }}
                    </div>
                </td>
                <td
                    v-if="reportAvailable"
                    class="bg-gray-light"
                    style="vertical-align: middle"
                >
                    <div class="text-nowrap">
                        {{
                            report.statusChangeToInoperableCount?.total || 0
                        }}
                    </div>
                </td>
            </tr>
            <tr>
                <th style="vertical-align: middle">
                    <div>
                        Work Orders&nbsp;&nbsp;<info-icon
                        tooltip="The number of work orders created and/or resolved/closed during the selected timeframe."
                    />
                    </div>
                </th>
                <td
                    v-if="reportAvailable"
                    class="bg-gray-light"
                    style="vertical-align: middle"
                >
                    <div>
                        <div
                            class="text-nowrap d-flex justify-content-between"
                        >
                            <div>Created:</div>
                            <div class="px-md-5 text-align-end">
                                {{
                                    report.workOrdersCount
                                        ?.correctiveMaintenance?.created ||
                                    0
                                }}
                            </div>
                        </div>
                        <div
                            class="text-nowrap d-flex justify-content-between"
                        >
                            <div>Resolved/Closed:</div>
                            <div class="px-md-5 text-align-end">
                                {{
                                    sum(
                                        report.workOrdersCount
                                            ?.correctiveMaintenance
                                            ?.resolved,
                                        report.workOrdersCount
                                            ?.correctiveMaintenance?.closed
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </td>
                <td
                    v-if="reportAvailable"
                    class="bg-gray-light"
                    style="vertical-align: middle"
                >
                    <div>
                        <div
                            class="text-nowrap d-flex justify-content-between"
                        >
                            <div>Created:</div>
                            <div class="px-md-5 text-align-end">
                                {{
                                    report.workOrdersCount
                                        ?.preventativeMaintenance
                                        ?.created || 0
                                }}
                            </div>
                        </div>
                        <div
                            class="text-nowrap d-flex justify-content-between"
                        >
                            <div>Resolved/Closed:</div>
                            <div class="px-md-5 text-align-end">
                                {{
                                    sum(
                                        report.workOrdersCount
                                            ?.preventativeMaintenance
                                            ?.resolved,
                                        report.workOrdersCount
                                            ?.preventativeMaintenance
                                            ?.closed
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </td>
                <td
                    v-if="reportAvailable"
                    class="bg-gray-light"
                    style="vertical-align: middle"
                >
                    <div>
                        <div
                            class="text-nowrap d-flex justify-content-between"
                        >
                            <div>Created:</div>
                            <div class="px-md-5 text-align-end">
                                {{
                                    report.workOrdersCount?.total
                                        ?.created || 0
                                }}
                            </div>
                        </div>
                        <div
                            class="text-nowrap d-flex justify-content-between"
                        >
                            <div>Resolved/Closed:</div>
                            <div class="px-md-5 text-align-end">
                                {{
                                    sum(
                                        report.workOrdersCount?.total
                                            ?.resolved,
                                        report.workOrdersCount?.total
                                            ?.closed
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <th style="vertical-align: middle">
                    <div>
                        Downtime&nbsp;&nbsp;<info-icon
                        tooltip="The downtime of all inoperable assets during the selected timeframe."
                    />
                    </div>
                </th>
                <td
                    v-if="reportAvailable"
                    class="bg-gray-light"
                    style="vertical-align: middle"
                >
                    <div class="text-nowrap">
                        {{
                            $filters.duration((report.downtime?.correctiveMaintenance || 0), currentLocale, {
                                style: 'narrow',
                            })
                        }}
                    </div>
                </td>
                <td
                    v-if="reportAvailable"
                    class="bg-gray-light"
                    style="vertical-align: middle"
                >
                    <div class="text-nowrap">
                        {{
                            $filters.duration((report.downtime?.preventativeMaintenance || 0), currentLocale, {
                                style: 'narrow',
                            })
                        }}
                    </div>
                </td>
                <td
                    v-if="reportAvailable"
                    class="bg-gray-light"
                    style="vertical-align: middle"
                >
                    <div class="text-nowrap">
                        {{
                            $filters.duration((report.downtime?.total || 0), currentLocale, {
                                style: 'narrow',
                            })
                        }}
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import AdditionalStatusInformation from '@/Components/AdditionalStatusInformation.vue';
import StatusBadge from '@/Components/StatusBadge.vue';
import StatusCheckDate from '@/Components/StatusCheckDate.vue';
import WorkOrderPriority from '@/Pages/WorkOrders/Modals/WorkOrderPriority.vue';
import WorkOrderTypeIcon from '@/Pages/WorkOrders/Modals/WorkOrderTypeIcon.vue';
import Pagination from '@/Components/Pagination.vue';
import InfoIcon from '@/Components/InfoIcon.vue';

export default {
    name: 'weekly-light-report-table',
    components: {
        InfoIcon,
        Pagination,
        AdditionalStatusInformation,
        StatusBadge,
        StatusCheckDate,
        WorkOrderPriority,
        WorkOrderTypeIcon,
    },
    data() {
        return {
            activeQueryParams: {},
            noItemsView: {
                noItems: 'No report available',
            },
        };
    },
    computed: {
        report() {
            return this.$page.props.report;
        },
        reportAvailable() {
            return this.report?.locationIds?.length > 0;
        },
    },
    methods: {
        sum(a, b) {
            return (a || 0) + (b || 0);
        },
    },
};
</script>
