<template>
    <dialog-modal
        v-if="
            shouldRender &&
            hasFeature('asset-work-order-management') &&
            hasAnyPermission('workorder.create')
        "
        :cancel-disabled="uploadInProgress"
        :is-processing="form.processing"
        :primary-button-label="__('general.action.create')"
        :show="show"
        :submit-disabled="!assetId || !form.isDirty || uploadInProgress"
        max-width="lg"
        @close="closeModal"
        @submit="submitForm"
    >
        <template #title>{{ __('workorder.modal.create.title') }}</template>
        <asset-selection-field
            v-if="showAssetSelection"
            :error="$page.props.errors.assetId"
            :label="__('workorder.modal.create.asset_selection.label')"
            :placeholder="
                __('workorder.modal.create.asset_selection.placeholder')
            "
            :required="true"
            :search-url="$route('workorders.create')"
            @selected="loadAssetDetails"
        />
        <work-order-form
            :asset-id="assetId"
            :form="form"
            :is-type-forced="!!forcedWorkOrderType"
            :show-title="!showAssetSelection"
            @upload-started="uploadInProgress = true"
            @upload-completed="uploadInProgress = false"
        />
    </dialog-modal>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import WorkOrderForm from '@/Pages/WorkOrders/WorkOrderForm/Form.vue';
import AssetSelectionField from '@/Pages/WorkOrders/WorkOrderForm/AssetSelectionField.vue';
import { eventBus, events } from '@/eventBus.js';

export default {
    components: {
        AssetSelectionField,
        WorkOrderForm,
        DialogModal,
        JetButton,
    },
    props: ['forcedWorkOrderType'],
    emits: ['close', 'created'],
    data() {
        return {
            assetId: null,
            showAssetSelection: false,
            uploadInProgress: false,
            show: false,
            shouldRender: false,
            form: this.$inertia.form({
                type: 'corrective_maintenance',
                priority: 'medium',
                assignee: 'unassigned',
                startDate: null,
                dueDate: null,
                title: null,
                description: null,
                ticketId: null,
                ticketLink: null,
                attachments: [],
            }),
        };
    },
    mounted() {
        eventBus.$on([events.openCreateWorkOrderModal], (data) => {
            this.showAssetSelection = !data?.assetId;
            this.assetId = data?.assetId || null;
            this.form.reset();
            this.form.clearErrors();
            this.uploadInProgress = false;

            this.shouldRender = true;
            this.$nextTick(() => {
                this.show = true;
            });
        });
    },
    beforeUnmount() {
        eventBus.$off([events.openCreateWorkOrderModal]);
    },
    methods: {
        loadAssetDetails(asset) {
            this.assetId = asset.id;
        },
        closeModal() {
            this.show = false;
            setTimeout(() => {
                this.shouldRender = false;
            }, 300);
        },
        submitForm() {
            if (!this.assetId) {
                return;
            }

            this.form
                .transform((data) => {
                    data.startDate = data.startDate?.toISODate();
                    data.dueDate = data.dueDate?.toISODate();
                    data.assetId = this.assetId;

                    if (!data.attachments) {
                        delete data.attachments;
                    }

                    return data;
                })
                .post(this.route('workorders.store'), {
                    preserveState: true,
                    onSuccess: () => {
                        this.$emit('created');
                        this.closeModal();
                    },
                });
        },
    },
};
</script>
