<template>
    <dialog-modal v-if="shouldRender" :show="show">
        <template #title>
            {{ __('qr_code_scanner.modal.header') }}
        </template>
        <template #subtitle>
            {{ __('qr_code_scanner.modal.description') }}
        </template>
        <qr-code-scanner
            v-if="show"
            :key="componentKey"
            ref="scanner"
            :valid="isValidQrCode"
            @failure="onError"
            @reset="isValidQrCode = undefined"
            @success="onSuccess"
        />
        <form
            v-if="scannerType !== scanIdType"
            @submit.prevent="onSuccess(form.petitec_identifier)"
        >
            <row class="mt-3">
                <column>
                    <jet-input
                        v-model="form.petitec_identifier"
                        :horizontal="false"
                        :invalidFeedback="form.errors.petitec_identifier"
                        :label="
                            __('assets.details.field.petitec_identifier.label')
                        "
                        :placeholder="
                            __(
                                'assets.details.field.petitec_identifier.placeholder',
                            )
                        "
                        :required="true"
                        append-classes="bg-primary border-primary"
                        class="bg-append-primary"
                    >
                        <template #append>
                            <icon icon="logoSmall" />
                        </template>
                    </jet-input>
                </column>
            </row>
        </form>
        <template #footer>
            <div class="w-100 d-flex justify-content-center align-items-center">
                <jet-button class="mx-2" color="secondary" @click="close"
                    >{{ __('general.action.cancel') }}
                </jet-button>
                <div v-if="scannerType !== scanIdType">
                    <jet-button
                        v-if="form.errors.petitec_identifier"
                        :is-processing="form.processing"
                        @click.stop="reset"
                    >
                        {{ __('general.action.retry') }}
                    </jet-button>
                    <jet-button
                        v-else
                        :disabled="!isFormSubmittable"
                        :is-processing="form.processing"
                        @click.stop="onSuccess(form.petitec_identifier)"
                    >
                        {{ primaryButtonText }}
                    </jet-button>
                </div>
            </div>
        </template>
    </dialog-modal>
</template>
<script>
import QrCodeScanner from '@/Components/QrCodeScanner.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetButton from '@/Jetstream/Button.vue';
import { $http } from '@/bootstrap.js';
import { eventBus, events } from '@/eventBus.js';
import { CInputGroupText } from '@coreui/vue-pro/dist/esm/components/form/index.js';

export const PetiteCIdScannerType = {
    attachPetiteCId: 'attachPetiteCId',
    searchAsset: 'searchAsset',
    scanId: 'scanId',
};

export default {
    components: {
        CInputGroupText,
        QrCodeScanner,
        DialogModal,
        JetInput,
        JetButton,
    },

    data() {
        return {
            componentKey: 0,
            show: false,
            shouldRender: false,
            assetId: null,
            isValidQrCode: undefined,
            scannerType: PetiteCIdScannerType.searchAsset,
            form: this.$inertia.form({
                petitec_identifier: null,
            }),
            onSuccessfulScan: null,
        };
    },
    computed: {
        primaryButtonText() {
            switch (this.scannerType) {
                case PetiteCIdScannerType.attachPetiteCId:
                    return this.__('general.action.save');
                case PetiteCIdScannerType.searchAsset:
                    return this.__('general.action.go');
            }
        },
        scanIdType() {
            return PetiteCIdScannerType.scanId;
        },
    },
    mounted() {
        eventBus.$on(events.openQrCodeScanner, (config) => {
            this.resetForm();
            this.assetId = config?.assetId;

            if (
                config?.type &&
                !Object.values(PetiteCIdScannerType).includes(config.type)
            ) {
                throw new Error(`Invalid scanner type ${config.type}`);
            }

            this.scannerType = config?.type || PetiteCIdScannerType.searchAsset;
            this.onSuccessfulScan = config?.onSuccessfulScan || null;

            this.shouldRender = true;
            this.$nextTick(() => {
                this.show = true;
            });
        });
    },

    methods: {
        onSuccess(code) {
            this.form.petitec_identifier = code;

            switch (this.scannerType) {
                case PetiteCIdScannerType.attachPetiteCId:
                    this.attachPetiteCId();
                    break;
                case PetiteCIdScannerType.searchAsset:
                    this.searchAsset();
                    break;
                case PetiteCIdScannerType.scanId:
                    this.onSuccessfulScan(code, this);
                    break;
            }
        },
        onError() {
            this.$emit('error');
        },
        reset() {
            this.resetForm();
            this.componentKey += 1;
        },
        resetForm() {
            this.form.reset();
            this.form.clearErrors();
            this.isValidQrCode = undefined;
        },
        searchAsset() {
            this.form.processing = true;

            $http
                .get(
                    route('assets.search', {
                        pid: this.form.petitec_identifier,
                    }),
                )
                .then((resp) => {
                    const asset = resp.data;
                    this.isValidQrCode = true;
                    this.close();
                    this.$nextTick(() => {
                        this.$inertia.visit(route('assets.show', asset.id));
                    });
                })
                .catch((err) => {
                    this.isValidQrCode = false;
                    this.form.errors.petitec_identifier = this.__(
                        'assets.overview.scanner.error.asset_not_found',
                    );
                })
                .finally(() => {
                    this.form.processing = false;
                });
        },
        attachPetiteCId() {
            this.form.put(this.route('assets.assign_pid', this.assetId), {
                onSuccess: () => {
                    this.isValidQrCode = true;

                    setTimeout(() => {
                        this.close();
                    }, 300);
                },
                onError: () => {
                    this.isValidQrCode = false;
                },
            });
        },
        close: function () {
            this.show = false;
            setTimeout(() => {
                this.shouldRender = false;
                this.scannerType = null;
                this.onSuccessfulScan = null;
            }, 300);
        },
    },
};
</script>
