<template>
    <div
        ref="container"
        :class="{ 'c-show': show }"
        class="multi-select date-filter"
        style="user-select: none; cursor: pointer"
        tabindex="0"
        @click="toggleVisibility"
        @keydown.esc="show = false"
    >
        <span
            class="multi-select-selection d-flex justify-content-between align-items-center"
            style="min-height: 2rem"
        >
            <span class="text-truncate text-nowrap">{{
                $filters.fullDate(modelValue, currentLocale)
            }}</span>
        </span>
        <div class="multi-select-options" style="width: auto" @click.stop="">
            <calendar
                :attributes="attributes"
                :disabled-dates="disabledDates"
                :first-day-of-week="2"
                :locale="currentLocale"
                is-expanded
                trim-weeks
                @dayclick="onDateSelect"
            >
                <template #day-popover="{ day, dayEvents }">
                    <div>
                        <div>
                            {{
                                $filters.fullDate(
                                    DateTime.fromJSDate(day.date),
                                    currentLocale,
                                )
                            }}
                        </div>
                        <div v-for="obj in day.attributes">
                            <span v-if="obj.popover">{{
                                obj.popover.label
                            }}</span>
                        </div>
                    </div>
                </template>
            </calendar>
        </div>
    </div>
</template>
<script>
import { Calendar } from 'v-calendar';
import { DateTime } from 'luxon';
import { onClickOutside } from '@vueuse/core';

export default {
    name: 'date-filter',
    computed: {
        DateTime() {
            return DateTime;
        },
    },
    components: { Calendar },
    props: {
        modelValue: String,
        attributes: Array,
        disabledDates: {
            type: Array,
            default: () => [],
        },
        selectionMode: {
            type: String,
            default: 'daily',
        },
    },
    data() {
        return {
            show: false,
        };
    },
    mounted() {
        onClickOutside(this.$refs.container, this.hideElement);
    },
    methods: {
        hideElement() {
            this.show = false;
        },
        toggleVisibility() {
            if (this.disabled) {
                this.show = false;
            }
            this.show = !this.show;
        },
        onDateSelect(event) {
            let date = DateTime.fromJSDate(event.date);
            if (!!event.isDisabled) {
                return;
            }

            if (this.selectionMode === 'weekly') {
                date = date.endOf('week');
            }

            this.$emit('update:modelValue', date.toFormat('yyyy-MM-dd'));
        },
    },
};
</script>
<style scoped>
:deep(.vc-day.is-not-in-month *) {
    opacity: 1 !important;
}

:deep(.vc-day.is-not-in-month .vc-day-content) {
    opacity: 0.3 !important;
}
</style>
