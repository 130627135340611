<template>
    <app-layout>
        <page-header :title="__('airports.overview.title')" />
        <card>
            <template #header>
                <custom-card-header
                    :href="$route('airports.create')"
                    :label="__('airports.overview.action.add_airport')"
                    :show-action="hasAnyPermission('physical_location.create')"
                    :title="__('airports.overview.title')"
                />
            </template>
            <div class="d-flex justify-content-end mb-1">
                <small class="text-muted"
                    >{{
                        __('airports.overview.results.count', {
                            number_of_airports: `${transformedAirports.length}`,
                        })
                    }}
                </small>
            </div>
            <data-table
                v-if="airports"
                :actions="actions"
                :columns="visibleFields"
                :default-sorting="{ column: 'icao_code', asc: true }"
                :items="transformedAirports"
                :no-items-view="noItemsView"
                :sticky-header="{ top: '124px' }"
                @update:sorter-value="updateSorting"
                @row-clicked="editAirport"
            >
                <template #name="{ item }">
                    <td class="align-middle">
                        <div>
                            {{ item.name }}
                        </div>
                        <div class="d-flex align-items-center">
                            <current-time :timezone="item.timezone" />
                        </div>
                    </td>
                </template>

                <template #country="{ item }">
                    <td class="align-middle">
                        <div class="d-flex align-items-center text-center">
                            <icon
                                v-if="item.country_code_alpha2"
                                :name="'cif-' + item.country_code_alpha2"
                                class="mx-1"
                            />
                            {{ item.country }}
                        </div>
                    </td>
                </template>

                <template #users="{ item }">
                    <td class="align-middle">
                        <div class="d-flex align-items-center text-center">
                            <span
                                v-c-tooltip="{
                                    content: __(
                                        'airports.overview.cell.users.tooltip.active',
                                    ),
                                    popperOptions: {
                                        positionFixed: true,
                                    },
                                }"
                                >{{ getActiveUsers(item.users).length }}</span
                            >&nbsp;/&nbsp;
                            <span
                                v-c-tooltip="{
                                    content: __(
                                        'airports.overview.cell.users.tooltip.total',
                                    ),
                                    popperOptions: {
                                        positionFixed: true,
                                    },
                                }"
                                >{{ item.users.length }}</span
                            >
                        </div>
                    </td>
                </template>

                <template #assets="{ item }">
                    <td class="align-middle">
                        <div class="d-flex align-items-center text-center">
                            {{ item.assets_count }}
                        </div>
                    </td>
                </template>
            </data-table>
            <template v-if="hasMultiplePages(airports)" #footer>
                <pagination :paginator="airports" />
            </template>
        </card>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import Pagination from '@/Components/Pagination.vue';
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import CurrentTime from '@/Components/CurrentTime.vue';
import Card from '@/Components/Card.vue';
import DataTable from '@/Components/DataTable.vue';
import { hasMultiplePages } from '@/Utils/HasMultiplePages';
import PageHeader from '@/Components/PageHeader.vue';

export default {
    components: {
        PageHeader,
        DataTable,
        Card,
        CurrentTime,
        AppLayout,
        CustomCardHeader,
        JetButton,
        Pagination,
    },

    data() {
        return {
            allFields: [
                {
                    key: 'icao_code',
                    label: this.__('airports.overview.cell.icao'),
                },
                {
                    key: 'iata_code',
                    label: this.__('airports.overview.cell.iata'),
                },
                {
                    key: 'name',
                    label: this.__('airports.overview.cell.name'),
                },
                {
                    key: 'country',
                    label: this.__('airports.overview.cell.country'),
                    sorter: false,
                },
                {
                    key: 'users',
                    label: this.__('airports.overview.cell.users'),
                    sorter: false,
                },
                {
                    key: 'assets',
                    label: this.__('airports.overview.cell.assets'),
                    sorter: false,
                },
            ],
            noItemsView: {
                noResults: this.__('airports.overview.filters.empty'),
                noItems: this.__('airports.overview.results.empty'),
            },
            actions: [
                {
                    permissions: ['physical_location.update'],
                    tooltip: this.__('airports.overview.tooltip.edit_airport'),
                    type: 'primary',
                    click: this.editAirport,
                    icon: 'cil-pencil',
                },
                {
                    permissions: ['physical_location.delete'],
                    tooltip: this.__(
                        'airports.overview.tooltip.delete_airport',
                    ),
                    type: 'danger',
                    click: this.deleteAirport,
                    icon: 'cil-trash',
                },
            ],
        };
    },

    computed: {
        airports() {
            return this.$page.props.airports;
        },
        visibleFields() {
            return this.allFields;
        },
        transformedAirports() {
            const notAvailable = this.__('general.not_available');

            return this.airports.data.map((airport) => ({
                id: airport.id,
                icao_code: airport.icao_code || notAvailable,
                iata_code: airport.iata_code || notAvailable,
                name: airport.name || notAvailable,
                country: airport.country_code_alpha2 || notAvailable,
                country_code_alpha2: airport.country_code_alpha2?.toLowerCase(),
                users: airport.users,
                assets_count: airport.assets_count,
                timezone: airport.timezone.identifier,
            }));
        },
    },

    mounted() {
        const primarySortOption = this.$page.props.sort_order[0];
        this.sortOrder = {
            column: primarySortOption?.column,
            asc: primarySortOption?.direction === 'asc',
        };
    },

    methods: {
        hasMultiplePages,
        editAirport(airport) {
            this.$inertia.visit(this.route('airports.edit', airport.id));
        },
        deleteAirport(airport) {
            this.$inertia.delete(this.route('airports.destroy', airport.id));
        },
        getActiveUsers(users) {
            return users.filter((user) => user.status.value === 'active');
        },
        updateSorting(event) {
            let sortOrder = event.asc ? 'asc' : 'desc';
            this.$inertia.visit(
                this.route(this.route().current(), {
                    sort: `${event.column}.${sortOrder}`,
                }),
                {
                    preserveState: true,
                },
            );
        },
    },
};
</script>
