<template>
    <app-layout>
        <page-header :title="__('assets.overview.headline')">
            <template #action>
                <jet-button
                    class="text-nowrap sm-full-width"
                    color="primary"
                    @click="openQrCodeScanner"
                    >{{ __('assets.overview.scanner.title') }}
                </jet-button>
            </template>
            <filter-bar
                :is-processing="isProcessing"
                class="mt-2"
                @change="updateFilter"
            />
        </page-header>
        <div class="d-flex justify-content-end">
            <small class="text-muted"
                >{{
                    __('assets.overview.results.count', {
                        assets_count: `${transformedAssets.length}/${totalAssets}`,
                    })
                }}
            </small>
        </div>
        <data-table
            v-if="assets"
            :columns="visibleFields"
            :default-sorting="activeQueryParams.sort"
            :items="transformedAssets"
            :no-items-view="noItemsView"
            :selected-items="selectedItems"
            :selection-enabled="true"
            :sticky-header="{ top: '152px' }"
            :total-items-count="assets.meta.total"
            @row-clicked="showDetails"
            @update:sorter-value="updateSorting($event)"
            @select:add="addSelectedItem"
            @select:remove="removeSelectedItem"
        >
            <template #manufacturer="{ item }">
                <td>
                    {{ item.manufacturer }}
                </td>
            </template>

            <template #type="{ item }">
                <td>
                    <div>{{ item.type }}</div>
                </td>
            </template>

            <template #designation="{ item }">
                <td>
                    <div>
                        {{ item.designation }}
                    </div>
                    <small class="text-nowrap text-dark"
                        >{{ __('assets.overview.cell.serial_number') }}:
                        {{ item.serialNumber }}</small
                    >
                </td>
            </template>

            <template #airport="{ item }">
                <td>
                    <span
                        v-c-tooltip="{
                            content: item.airport_name,
                            popperOptions: {
                                positionFixed: true,
                            },
                        }"
                    >
                        {{ item.airport }}
                    </span>
                </td>
            </template>

            <template #location="{ item }">
                <td>
                    {{ item.location }}
                </td>
            </template>
            <template #status="{ item }">
                <td>
                    <div>
                        <div
                            class="d-flex justify-content-between align-items-top"
                        >
                            <status-badge
                                :operability="item.status.operability"
                                :usage="item.status.usageCategory"
                            />
                            <Link
                                v-if="item.status.workOrdersInProgress > 0"
                                :href="
                                    route('assets.workorders.index', item.id)
                                "
                                @click.stop=""
                            >
                                <work-order-badge
                                    v-c-tooltip="{
                                        content: __(
                                            'assets.overview.tooltip.work_orders_in_progress',
                                            {
                                                '%d': item.status
                                                    .workOrdersInProgress,
                                            },
                                        ),
                                    }"
                                    :count="item.status.workOrdersInProgress"
                                />
                            </Link>
                        </div>
                        <small
                            v-if="
                                item.status.hasOwnProperty(
                                    'operabilityLastCheckedAt',
                                ) && hasAnyPermission('asset.status_check.read')
                            "
                            class="text-nowrap text-muted"
                        >
                            <status-check-date :status="item.status" />
                        </small>
                    </div>
                </td>
            </template>

            <template #issues="{ item }">
                <td>
                    <div class="align-middle">
                        <div v-for="issue in item.issues">
                            <onboarded-asset-issue
                                v-if="issue.type === 'onboarding.incomplete'"
                                @open-scanner="openQrCodeScanner(item)"
                            />
                            <issue-badge v-else :issue="issue" />
                        </div>
                        <div v-if="item.scores">
                            <div
                                v-for="(score, label) in item.scores.data"
                                :key="label"
                                class="d-flex text-muted text-sm text-uppercase justify-content-between"
                            >
                                <div class="mr-2">
                                    <small>{{ label }}:</small>
                                </div>
                                <div>
                                    <small>{{ score }}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </template>
        </data-table>
        <pagination :paginator="assets" :query="activeQueryParams" />
        <batch-jobs
            v-if="selectedItems && hasFeature('asset-batch-operations')"
            :selected-items="selectedItems"
            @reset="resetSelection"
        />
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import Pagination from '@/Components/Pagination.vue';
import PetitecIdScanner, {
    PetiteCIdScannerType,
} from '@/Components/PetitecIdScanner.vue';
import FilterBar from '@/Components/FilterBar.vue';
import IssueBadge from '@/Components/IssueBadge.vue';
import StatusBadge from '@/Components/StatusBadge.vue';
import StatusCheckDate from '@/Components/StatusCheckDate.vue';
import BatchJobs from '@/Pages/Assets/BatchJobs/BatchJobs.vue';
import { debounce } from 'lodash';
import { removeEmptyProperties } from '@/utils';
import OnboardedAssetIssue from '@/Pages/Assets/OnboardedAssetIssue.vue';
import WorkOrderBadge from '@/Components/WorkorderBadge.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import DataTable from '@/Components/DataTable.vue';
import { Link } from '@inertiajs/vue3';

import { $http } from '@/bootstrap.js';
import { eventBus, events } from '@/eventBus.js';
import PageTitle from '@/Components/PageTitle.vue';
import PageHeader from '@/Components/PageHeader.vue';

export default {
    components: {
        PageHeader,
        PageTitle,
        DataTable,
        JetCheckbox,
        OnboardedAssetIssue,
        WorkOrderBadge,
        BatchJobs,
        AppLayout,
        JetButton,
        Pagination,
        PetitecIdScanner,
        IssueBadge,
        FilterBar,
        StatusBadge,
        StatusCheckDate,
        Link,
    },

    data() {
        return {
            activeQueryParams: { sort: { column: 'status', asc: false } },
            isProcessing: false,
            allFields: [
                {
                    key: 'airport',
                    label: this.__('assets.overview.cell.airport'),
                    _style: 'width: 90px',
                },
                {
                    key: 'location',
                    label: this.__('assets.overview.cell.location'),
                    _style: 'width: 15%; white-space: nowrap;',
                },
                {
                    key: 'type',
                    label: this.__('assets.overview.cell.type'),
                    _style: 'width: 90px',
                },
                {
                    key: 'designation',
                    label: this.__('assets.overview.cell.designation'),
                    _style: 'width: 20%',
                },
                {
                    key: 'manufacturer',
                    label: this.__('assets.overview.cell.manufacturer'),
                },
                {
                    key: 'status',
                    label: this.__('assets.overview.cell.status'),
                    _style: 'width: 15%',
                },
                {
                    key: 'issues',
                    label: this.__('assets.overview.cell.issues'),
                    _style: 'width: 15%',
                },
            ],
            visibleFields: [],
            noItemsView: {
                noResults: this.__('assets.overview.filters.empty'),
                noItems: this.__('assets.overview.results.empty'),
            },
            isSearching: false,
            selectedAsset: null,
            isPetitecIdScannerVisible: false,
            selectedItems: [],
            areAllItemsForActiveFiltersSelected: false,
        };
    },

    computed: {
        assets() {
            return this.$page.props.assets;
        },
        totalAssets() {
            return this.assets.meta.total;
        },
        hasCameraSupport() {
            return !!navigator.mediaDevices;
        },
        placement() {
            return document.dir === 'rtl' ? 'top-start' : 'top-end';
        },
        transformedAssets() {
            const notAvailable = this.__('general.not_available');

            return this.assets.data.map((asset) => ({
                id: asset.id,
                type: asset.type.value || notAvailable,
                designation: asset.designation
                    ? asset.designation.label
                    : notAvailable,
                manufacturer: asset.manufacturer
                    ? asset.manufacturer.name
                    : notAvailable,
                petitec_identifier: asset.petitec_identifier,
                serialNumber: asset.serialNumber || notAvailable,
                airport:
                    asset.location.physical_location.iata_code || notAvailable,

                airport_name:
                    asset.location.physical_location.name || notAvailable,
                location: this.getLocationName(asset.location),
                issues: asset.issues,
                status: asset.status,
                scores: asset.scores,
            }));
        },
        scanner() {
            return this.$refs.petitecIdScanner;
        },
        defaultSorting() {
            return { column: 'status', asc: false };
        },
        activeQueryParamsWithoutSorting() {
            const params = Object.assign({}, this.activeQueryParams);
            delete params.sort;
            return params;
        },
    },

    methods: {
        getLocationName(location) {
            if (!location) {
                return this.__('general.not_available');
            }

            return [location.name]
                .reduce(
                    (unique, item) =>
                        unique.includes(item) ? unique : [...unique, item],
                    [],
                )
                .filter((item) => item)
                .join(' - ');
        },
        showDetails(asset) {
            this.$inertia.visit(this.route('assets.show', asset.id));
        },
        openQrCodeScanner(item) {
            eventBus.$emit(events.openQrCodeScanner, {
                ...item,
                type: PetiteCIdScannerType.searchAsset,
            });
        },
        loadAssets() {
            const { sort } = this.activeQueryParams;

            let queryParams = Object.assign(
                {},
                removeEmptyProperties(this.activeQueryParams),
                {
                    sort: this.transformSortToQuery(sort),
                },
            );

            this.isProcessing = true;

            this.$inertia.get(
                this.route('assets.index', queryParams),
                {},
                {
                    preserveState: true,
                    onFinish: () => {
                        this.updateTable();
                        this.isProcessing = false;
                    },
                },
            );
        },
        updateTable() {
            this.visibleFields = this.allFields.filter((field) => {
                return !(
                    field.key === 'selection' &&
                    !this.hasFeature('asset-batch-operations')
                );
            });
        },
        updateFilter: debounce(function (filters) {
            this.activeQueryParams = { ...filters, sort: this.defaultSorting };
            this.loadAssets();
        }, 300),
        updateSorting(event) {
            this.activeQueryParams.sort = event ?? undefined;
            this.loadAssets();
        },
        transformSortToQuery(sort) {
            if (!sort) {
                return undefined;
            }

            let sortDirection = sort.asc ? 'asc' : 'desc';
            return `${sort.column}.${sortDirection}`;
        },
        transformQueryToSort(sort) {
            if (sort) {
                const parts = sort.split('.');
                if (parts.length === 2) {
                    return {
                        column: parts[0],
                        asc: parts[1] === 'asc',
                    };
                }
            }

            return this.defaultSorting;
        },
        addSelectedItem(id) {
            $http
                .post(
                    this.route(
                        'assets.batch.selection.add',
                        this.activeQueryParamsWithoutSorting,
                    ),
                    {
                        ids: [id],
                    },
                )
                .then((resp) => this.updateSelection(resp.data));
        },
        removeSelectedItem(id) {
            $http
                .post(
                    this.route(
                        'assets.batch.selection.remove',
                        this.activeQueryParamsWithoutSorting,
                    ),
                    {
                        ids: [id],
                    },
                )
                .then((resp) => this.updateSelection(resp.data));
        },
        resetSelection() {
            $http
                .post(this.route('assets.batch.selection.reset'))
                .then((resp) => this.updateSelection(resp.data));
        },
        updateSelection(data) {
            this.selectedItems = data.ids;
            this.areAllItemsForActiveFiltersSelected =
                this.selectedItems.length > 0 &&
                this.selectedItems.length === this.assets.meta.total;
        },
    },
    mounted() {
        const { search, airports, usages, operabilities, types, sort } =
            this.route().params;

        this.activeQueryParams = {
            search,
            airports,
            usages,
            operabilities,
            types,
            sort: this.transformQueryToSort(sort),
        };

        this.updateTable();

        this.$nextTick(function () {
            this.updateSelection(this.$page.props.selectedAssets);
        });
    },
};
</script>
