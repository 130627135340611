<template>
    <sidebar>
        <sidebar-header class="p-0">
            <div
                class="sidebar-brand-wrapper d-flex justify-content-center align-items-center pt-5 pb-4 w-100"
            >
                <sidebar-brand
                    class="d-flex justify-content-center align-items-center"
                    href="/"
                >
                    <img
                        v-if="logoUrl"
                        :src="logoUrl"
                        alt="App logo"
                        class="sidebar-brand-full"
                    />
                    <img
                        v-if="logoUrl"
                        :src="logoUrl"
                        alt="Small App logo"
                        class="sidebar-brand-narrow"
                        style="width: 42px; height: 42px"
                    />
                    <icon
                        v-else
                        icon="cil-image-plus"
                        style="width: 25px; height: 25px"
                    />
                </sidebar-brand>
            </div>
            <sidebar-toggler />
        </sidebar-header>
        <sidebar-nav>
            <sidebar-nav-item
                v-if="
                    hasAnyPermission('asset.update') &&
                    $page.props.assets_stats.onboardingIncomplete > 0
                "
                :count="$page.props.assets_stats.onboardingIncomplete"
                icon="cil-tags"
                routeName="assets.activation"
            />
            <sidebar-nav-item
                v-if="
                    hasFeature('asset-work-order-management') &&
                    hasAnyPermission('workorder.read')
                "
                icon="cil-view-column"
                routeName="workorders.index"
            />
            <sidebar-nav-item
                v-if="
                    hasFeature('dashboard') &&
                    hasAnyPermission('dashboard.read')
                "
                icon="cil-bar-chart"
                routeName="dashboard.index"
            />
            <sidebar-nav-item
                v-if="hasAnyPermission('asset.read')"
                :count="totalAssetsCount"
                icon="cil-layers"
                routeName="assets.index"
            >
                <ul
                    v-if="$page.props.activeNavigation['assets.index']"
                    class="list-unstyled sidebar-subnav"
                >
                    <sidebar-sub-nav-item
                        :active="routeContainsUsageCategory('active')"
                        :href="
                            route('assets.index', {
                                usages: ['active'],
                            })
                        "
                        class="d-block"
                    >
                        {{ __('assets.sidebar.active') }} ({{
                            countAssetsByUsageCategory('active')
                        }})
                    </sidebar-sub-nav-item>
                    <sidebar-sub-nav-item
                        :active="routeContainsUsageCategory('spare')"
                        :href="
                            route('assets.index', {
                                usages: ['spare'],
                            })
                        "
                        class="d-block"
                    >
                        {{ __('assets.sidebar.spare') }} ({{
                            countAssetsByUsageCategory('spare')
                        }})
                    </sidebar-sub-nav-item>
                    <sidebar-sub-nav-item
                        :active="routeContainsUsageCategory(['decommissioned'])"
                        :href="
                            route('assets.index', {
                                usages: ['decommissioned'],
                            })
                        "
                        class="d-block"
                    >
                        {{ __('assets.sidebar.decommissioned') }} ({{
                            countAssetsByUsageCategory('decommissioned')
                        }})
                    </sidebar-sub-nav-item>
                </ul>
            </sidebar-nav-item>
            <sidebar-nav-item
                v-if="
                    hasFeature('reporting-light') &&
                    hasAnyPermission('reporting_light.read')
                "
                icon="cil-description"
                routeName="reporting-light.index"
            />
            <li
                v-if="hasAnyPermission('asset.create')"
                class="nav-item pt-4 px-3 d-flex justify-content-center"
            >
                <CButton
                    class="btn btn-primary btn-shrink"
                    @click.stop="$inertia.visit(route('assets.create'))"
                >
                    <icon icon="cil-plus" style="shape-rendering: crispEdges" />
                    <span>{{
                        __('assets.details.create_new_asset')
                    }}</span></CButton
                >
            </li>
            <li
                v-if="hasAnyPermission('asset.read')"
                class="nav-item d-flex pt-4 px-3 justify-content-center"
            >
                <jet-button
                    class="btn btn-primary btn-shrink"
                    @click.stop="openQrCodeScanner"
                >
                    <icon
                        icon="cil-qr-code"
                        style="shape-rendering: crispEdges"
                    />
                    <span>{{
                        __('assets.overview.scanner.title')
                    }}</span></jet-button
                >
            </li>
            <sidebar-nav-title
                v-if="
                    hasAnyPermission([
                        'user.index',
                        'physical_location.index',
                        'settings.index',
                    ])
                "
                >{{ __('navigation.admin') }}
            </sidebar-nav-title>
            <sidebar-nav-item
                v-if="hasAnyPermission('user.index')"
                icon="cil-group"
                routeName="users.index"
            />
            <sidebar-nav-item
                v-if="hasAnyPermission('physical_location.index')"
                icon="cil-airplane-mode"
                routeName="airports.index"
            />
            <sidebar-nav-item
                v-if="
                    hasAnyPermission([
                        'owner.index',
                        'operator.index',
                        'maintainer.index',
                    ])
                "
                icon="cil-sitemap"
                routeName="stakeholders.index"
            />
            <sidebar-nav-item
                v-if="hasAnyPermission('settings.index')"
                icon="cil-cog"
                routeName="settings.index"
            />
        </sidebar-nav>
        <div class="d-flex flex-column align-items-center pt-3">
            <optional-link :href="curieTechUrl">
                <icon
                    class="logo-curie"
                    name="logoCurie"
                    style="height: 22px; width: auto"
                    title="Curie Technologies"
                />
            </optional-link>
            <div v-if="version" class="px-4 py-2 version text-center">
                <small :title="commit" class="text-muted"
                    ><b>v{{ version }}</b></small
                >
            </div>
        </div>
    </sidebar>
    <div
        v-if="sidebar.visible"
        :class="{ show: sidebar.visible }"
        class="fade sidebar-backdrop"
        @click="sidebar.close"
    />
</template>

<script setup>
import SidebarSubNavItem from '@/Components/SidebarSubNavItem.vue';
import OptionalLink from '@/Components/OptionalLink.vue';
import Sidebar from '@/Components/Sidebar.vue';
import SidebarNavItem from '@/Components/SidebarNavItem.vue';
import SidebarNav from '@/Components/SidebarNav.vue';
import SidebarNavTitle from '@/Components/SidebarNavTitle.vue';
import SidebarBrand from '@/Components/SidebarBrand.vue';
import SidebarToggler from '@/Components/SidebarToggler.vue';
import SidebarHeader from '@/Components/SidebarHeader.vue';
import { computed } from 'vue';
import { useSidebarStore } from '@/stores/sidebar.js';
import { usePage } from '@inertiajs/vue3';
import { isEqual } from 'lodash';
import { eventBus, events } from '@/eventBus.js';
import JetButton from '@/Jetstream/Button.vue';

const sidebar = useSidebarStore();

const $page = usePage();
const userManual = computed(() => `user_manual_${$page.props.locale}.pdf`);
const logoUrl = computed(() => $page.props.settings.big_logo_url);
const version = computed(() => $page.props.version?.semver);
const commit = computed(() => $page.props.version?.commit_hash);
const totalAssetsCount = computed(() =>
    Object.keys($page.props.assets_stats)
        .filter((key) => key !== 'onboardingIncomplete')
        .reduce((a, b) => a + $page.props.assets_stats[b], 0),
);
const curieTechUrl = computed(() => $page.props.curie_tech_url);
const isInventoryPage = computed(() =>
    [
        'assets.index',
        'assets.show',
        'assets.tests.index',
        'assets.workorders.index',
        'assets.activities.index',
    ].includes(route().current()),
);

// methods: {
function countAssetsByUsageCategory(usageCategory) {
    return $page.props.assets_stats[usageCategory] || 0;
}

function routeContainsUsageCategory(usageCategory) {
    if (route().current() !== 'assets.index') {
        return false;
    }

    const params = route().params;

    // Wrap in array if necessary
    usageCategory = Array.isArray(usageCategory)
        ? usageCategory
        : [usageCategory];

    return isEqual(params.usages, usageCategory);
}

function openQrCodeScanner() {
    eventBus.$emit(events.openQrCodeScanner);
}
</script>
<style lang="scss" scoped>
.version {
    display: block;
}

.version-spacer {
    flex-grow: 1;
}

@media (max-width: 991.98px) {
    .version {
        text-align: right;
        width: 100%;
    }
    .version-spacer {
        display: block;
        height: 50px;
    }
}

.btn-shrink {
    width: 100%;

    .icon {
        display: none;
    }
}

.sidebar-narrow-unfoldable {
    .btn-shrink {
        padding: 0 0.25rem;

        .icon {
            display: inline-block;
        }

        @media (max-width: 575px) {
            .icon {
                display: none;
            }
        }

        @media (min-width: 576px) {
            span {
                display: none;
            }
        }
    }

    &:hover .btn-shrink {
        padding: 0.1rem 0.5rem;

        .icon {
            display: none;
        }

        span {
            display: inline-block;
        }
    }

    .version,
    .logo-curie {
        display: none;
    }

    &:hover {
        .version,
        .logo-curie {
            display: inline-block;
        }
    }
}
</style>
