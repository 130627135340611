<template>
    <app-layout>
        <row>
            <column>
                <jet-form-section @submitted="handleSubmit">
                    <template #title>
                        {{ pageHeader }}
                    </template>

                    <template #form>
                        <h3>
                            {{
                                __('assets.details.asset_identification.header')
                            }}
                        </h3>
                        <p>
                            {{
                                __(
                                    'assets.details.asset_identification.description',
                                )
                            }}
                        </p>
                        <div>
                            <row>
                                <column>
                                    <jet-input
                                        v-model="form.petitec_identifier"
                                        :description="
                                            __(
                                                'assets.details.field.petitec_identifier.description',
                                            )
                                        "
                                        :horizontal="false"
                                        :invalidFeedback="
                                            form.errors.petitec_identifier
                                        "
                                        :label="
                                            __(
                                                'assets.details.field.petitec_identifier.label',
                                            )
                                        "
                                        :placeholder="
                                            __(
                                                'assets.details.field.petitec_identifier.placeholder',
                                            )
                                        "
                                        style="flex-grow: 1"
                                    >
                                        <template #prepend>
                                            <icon
                                                icon="cil-qr-code"
                                                style="
                                                    shape-rendering: crispEdges;
                                                "
                                            />
                                        </template>
                                        <template
                                            v-if="hasCameraSupport"
                                            #appendButton
                                        >
                                            <jet-button
                                                class="border-primary"
                                                type="button"
                                                @click="openPetitecQrCodeModal"
                                            >
                                                {{
                                                    __(
                                                        'assets.details.field.petitec_identifier.button.scan',
                                                    )
                                                }}
                                            </jet-button>
                                        </template>
                                    </jet-input>
                                </column>
                            </row>
                            <row>
                                <column>
                                    <jet-input
                                        v-model="form.serial_number"
                                        :horizontal="false"
                                        :invalidFeedback="
                                            form.errors.serial_number
                                        "
                                        :label="
                                            __(
                                                'assets.details.field.serial_number.label',
                                            )
                                        "
                                        :placeholder="
                                            __(
                                                'assets.details.field.serial_number.placeholder',
                                            )
                                        "
                                        :required="true"
                                    />
                                </column>
                            </row>

                            <row>
                                <column>
                                    <jet-input
                                        v-model="form.customer_tracking_number"
                                        :horizontal="false"
                                        :invalidFeedback="
                                            form.errors.customer_tracking_number
                                        "
                                        :label="
                                            __(
                                                'assets.details.field.customer_tracking_number.label',
                                            )
                                        "
                                        :placeholder="
                                            __(
                                                'assets.details.field.customer_tracking_number.placeholder',
                                            )
                                        "
                                    />
                                </column>
                            </row>

                            <row>
                                <column>
                                    <jet-select
                                        v-model="selectedPhysicalLocationId"
                                        :enable-search="true"
                                        :horizontal="false"
                                        :invalidFeedback="
                                            form.errors.location_id &&
                                            !selectedPhysicalLocationId
                                                ? __(
                                                      'assets.details.field.physical_location.error',
                                                  )
                                                : undefined
                                        "
                                        :label="
                                            __(
                                                'assets.details.field.physical_location.label',
                                            )
                                        "
                                        :options="locations || []"
                                        :placeholder="
                                            __(
                                                'assets.details.field.physical_location.placeholder',
                                            )
                                        "
                                        :required="true"
                                        @update:model-value="
                                            handlePhysicalLocationSelection(
                                                $event,
                                            )
                                        "
                                    >
                                        <template
                                            v-slot:item-label="{ option }"
                                        >
                                            <div
                                                class="d-flex justify-content-between align-items-center"
                                            >
                                                <span class="mr-3">{{
                                                    option.label
                                                }}</span
                                                ><small class="text-muted">{{
                                                    option.iata_code
                                                }}</small>
                                            </div>
                                        </template>
                                    </jet-select>
                                </column>
                            </row>
                            <row>
                                <column>
                                    <jet-select
                                        v-model="form.location_id"
                                        :disabled="
                                            !selectedPhysicalLocationId ||
                                            isLoadingSpecificLocations
                                        "
                                        :horizontal="false"
                                        :invalidFeedback="
                                            form.errors.location_id
                                        "
                                        :label="
                                            __(
                                                'assets.details.field.specific_location.label',
                                            )
                                        "
                                        :options="specificLocations || []"
                                        :placeholder="
                                            __(
                                                'assets.details.field.specific_location.placeholder',
                                            )
                                        "
                                        :required="true"
                                    >
                                        <template #empty
                                            >{{
                                                __(
                                                    'locations.field.specific_location.empty',
                                                )
                                            }}
                                        </template>
                                        <template #description>
                                            <div
                                                v-if="
                                                    !selectedPhysicalLocationId
                                                "
                                            >
                                                <small
                                                    class="form-text text-muted w-100"
                                                    v-text="
                                                        __(
                                                            'locations.field.specific_location.description',
                                                        )
                                                    "
                                                />
                                            </div>
                                        </template>
                                        <template #actions>
                                            <button-add-new
                                                v-if="
                                                    hasAnyPermission(
                                                        'physical_location.child.create',
                                                    ) &&
                                                    selectedPhysicalLocationId
                                                "
                                                @click.native="
                                                    createNewSpecificLocation(
                                                        selectedPhysicalLocationId,
                                                    )
                                                "
                                            />
                                        </template>
                                    </jet-select>
                                </column>
                            </row>
                            <row>
                                <column>
                                    <manufacturer-dropdown
                                        v-model="form.manufacturer"
                                        :invalidFeedback="
                                            form.errors.model_id &&
                                            !form.manufacturer
                                                ? __(
                                                      'assets.details.field.manufacturer.error',
                                                  )
                                                : undefined
                                        "
                                        @update:model-value="
                                            form.model_id = null
                                        "
                                    />
                                </column>
                            </row>
                            <row>
                                <column>
                                    <designation-dropdown
                                        v-model="form.model_id"
                                        :invalidFeedback="form.errors.model_id"
                                        :manufacturer="form.manufacturer"
                                    />
                                </column>
                            </row>
                            <row>
                                <column>
                                    <jet-select
                                        v-model="form.screening_application"
                                        :horizontal="false"
                                        :invalidFeedback="
                                            form.errors.screening_application
                                        "
                                        :label="
                                            __(
                                                'assets.details.field.screening_application.label',
                                            )
                                        "
                                        :options="screeningApplications"
                                        :placeholder="
                                            __(
                                                'assets.details.field.screening_application.placeholder',
                                            )
                                        "
                                        :required="true"
                                    />
                                </column>
                            </row>
                            <row>
                                <column>
                                    <jet-select
                                        v-model="form.critical"
                                        :horizontal="false"
                                        :invalidFeedback="form.errors.critical"
                                        :label="
                                            __(
                                                'assets.details.field.criticality.label',
                                            )
                                        "
                                        :options="criticalities"
                                        :required="true"
                                    />
                                </column>
                            </row>
                        </div>

                        <div class="mt-5">
                            <h3>
                                {{
                                    __(
                                        'assets.details.asset_specification.header',
                                    )
                                }}
                            </h3>
                            <p>
                                {{
                                    __(
                                        'assets.details.asset_specification.description',
                                    )
                                }}
                            </p>
                        </div>

                        <row>
                            <column>
                                <jet-datepicker
                                    v-model="form.purchased_at"
                                    :horizontal="false"
                                    :invalidFeedback="form.errors.purchased_at"
                                    :label="
                                        __('assets.details.label.purchased_at')
                                    "
                                    :placeholder="
                                        __(
                                            'assets.details.field.purchased_at.placeholder',
                                        )
                                    "
                                />
                            </column>
                        </row>
                        <row>
                            <column>
                                <jet-datepicker
                                    v-model="form.deployed_at"
                                    :horizontal="false"
                                    :invalidFeedback="form.errors.deployed_at"
                                    :label="
                                        __('assets.details.label.deployed_at')
                                    "
                                    :placeholder="
                                        __(
                                            'assets.details.field.deployed_at.placeholder',
                                        )
                                    "
                                />
                            </column>
                        </row>
                        <row>
                            <column>
                                <jet-datepicker
                                    v-model="form.warranty_start_date"
                                    :horizontal="false"
                                    :invalidFeedback="
                                        form.errors.warranty_start_date
                                    "
                                    :label="
                                        __(
                                            'assets.details.label.warranty_start_date',
                                        )
                                    "
                                    :placeholder="
                                        __('general.form.select.placeholder')
                                    "
                                />
                            </column>
                        </row>
                        <row>
                            <column>
                                <jet-datepicker
                                    v-model="form.warranty_end_date"
                                    :horizontal="false"
                                    :invalidFeedback="
                                        form.errors.warranty_end_date
                                    "
                                    :label="
                                        __(
                                            'assets.details.label.warranty_end_date',
                                        )
                                    "
                                    :min-date="form.warranty_start_date"
                                    :placeholder="
                                        __('general.form.select.placeholder')
                                    "
                                />
                            </column>
                        </row>

                        <div class="mt-5">
                            <h3>
                                {{
                                    __('assets.details.qa_configuration.header')
                                }}
                            </h3>
                            <p>
                                {{
                                    __(
                                        'assets.details.qa_configuration.description',
                                    )
                                }}
                            </p>
                        </div>

                        <row class="mt-4">
                            <column v-if="hasFeature('asset-status-check')">
                                <cadence-config-form
                                    v-model="form.statusCheckCadence"
                                    :errors="form.errors.statusCheckCadence"
                                    :title="
                                        __(
                                            'assetstatuschecks.configuration.form.cadence.title',
                                        )
                                    "
                                />
                            </column>
                        </row>
                        <row>
                            <column>
                                <cadence-config-form
                                    v-model="form.testCadence"
                                    :errors="form.errors.testCadence"
                                    :title="
                                        __(
                                            'tests.configuration.form.cadence.title',
                                        )
                                    "
                                />
                            </column>
                        </row>
                        <row>
                            <column>
                                <cadence-config-form
                                    v-model="form.maintenanceCadence"
                                    :errors="form.errors.maintenanceCadence"
                                    :title="
                                        __(
                                            'maintenances.configuration.form.cadence.title',
                                        )
                                    "
                                />
                            </column>
                        </row>

                        <div
                            v-if="
                                hasAnyPermission([
                                    'asset.attachment.index',
                                    'asset.attachment.create',
                                    'asset.attachment.update',
                                ])
                            "
                            class="mt-5"
                        >
                            <h3 id="attachments">
                                {{ __('attachments.overview.header') }}
                            </h3>
                            <row
                                v-if="
                                    hasAnyPermission(['asset.attachment.index'])
                                "
                            >
                                <column>
                                    <file-list
                                        :attachments="attachments"
                                        :can-delete="
                                            hasAnyPermission([
                                                'asset.attachment.delete',
                                            ])
                                        "
                                        :can-edit="
                                            hasAnyPermission([
                                                'asset.attachment.update',
                                            ])
                                        "
                                        :can-read="
                                            hasAnyPermission([
                                                'asset.attachment.index',
                                            ])
                                        "
                                    />
                                </column>
                            </row>
                            <row
                                v-if="
                                    hasAnyPermission([
                                        'asset.attachment.create',
                                        'asset.attachment.update',
                                    ])
                                "
                            >
                                <column>
                                    <p>
                                        {{
                                            __(
                                                'general.file_upload.description',
                                            )
                                        }}
                                    </p>
                                    <file-upload
                                        v-model="form.attachments"
                                        :invalid-feedback="
                                            form.errors['attachments.0']
                                        "
                                    />
                                </column>
                            </row>
                        </div>
                    </template>

                    <template #actions>
                        <jet-form-buttons
                            :cancel-route="getCancelRoute()"
                            :disabled="!isFormSubmittable"
                            :is-processing="form.processing"
                            :is-update-form="isUpdateForm"
                        />
                    </template>
                </jet-form-section>
            </column>
        </row>
        <create-specific-location-modal
            @success="handleCreateSpecificLocationSuccess"
        />
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetSelect from '@/Jetstream/Select.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetFormButtons from '@/Jetstream/FormButtons.vue';
import JetDatepicker from '@/Jetstream/Datepicker.vue';
import FileUpload from '@/Components/FileUpload.vue';
import FileList from '@/Components/FileList.vue';
import SimpleQrCodeScannerModal from '@/Components/SimpleQrCodeScannerModal.vue';
import CreateSpecificLocationModal from '@/Pages/Assets/Modals/CreateSpecificLocationModal.vue';
import DropdownWithAction from '@/Components/DropdownWithAction.vue';
import DesignationDropdown from '@/Pages/Assets/Edit/DesignationDropdown.vue';
import CadenceConfigForm from '@/Components/CadenceConfigForm.vue';
import { DateTime } from 'luxon';
import ManufacturerDropdown from '@/Pages/Assets/Edit/ManufacturerDropdown.vue';
import ButtonAddNew from '@/Components/ButtonAddNew.vue';
import { eventBus, events } from '@/eventBus.js';
import { $http } from '@/bootstrap.js';
import { PetiteCIdScannerType } from '@/Components/PetitecIdScanner.vue';

export default {
    components: {
        ButtonAddNew,
        ManufacturerDropdown,
        AppLayout,
        CadenceConfigForm,
        CreateSpecificLocationModal,
        DesignationDropdown,
        DropdownWithAction,
        JetButton,
        JetFormButtons,
        JetInput,
        JetFormSection,
        JetSelect,
        JetDatepicker,
        FileUpload,
        FileList,
        SimpleQrCodeScannerModal,
    },

    data() {
        return {
            models: [],
            selectedPhysicalLocationId: null,
            physicalLocations: [],
            specificLocations: [],
            isLoadingSpecificLocations: false,
            isLoadingModels: false,
            form: this.$inertia.form({
                petitec_identifier: null,
                manufacturer: null,
                serial_number: null,
                customer_tracking_number: null,
                owner: null,
                operator: null,
                maintainer: null,
                location_id: null,
                model_id: null,
                screening_application: null,
                purchased_at: null,
                deployed_at: null,
                attachments: [],
                statusCheckCadence: null,
                testCadence: null,
                maintenanceCadence: null,
                critical: 'no',
                warranty_start_date: null,
                warranty_end_date: null,
            }),
        };
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },

        attachments() {
            return this.$page.props.attachments?.data;
        },

        types() {
            return this.$page.props.types.data;
        },

        screeningApplications() {
            return this.$page.props.screening_applications.data;
        },

        criticalities() {
            return this.$page.props.criticalities.data;
        },

        isUpdateForm() {
            return !!this.asset;
        },

        hasCameraSupport() {
            return !!navigator.mediaDevices;
        },

        pageHeader() {
            return this.isUpdateForm
                ? this.__('assets.details.edit_asset')
                : this.__('assets.details.create_new_asset');
        },

        noModelsAvailable() {
            if (this.models && this.models.length === 0) {
                return this.__('designations.not_available');
            }

            return undefined;
        },
        isAllowedToCreateModels() {
            return this.hasAnyPermission('model.create');
        },
        locations() {
            return (
                this.$page.props.physical_locations?.data.map((loc) => ({
                    label: loc.name,
                    value: loc.id,
                    iata_code: loc.iata_code,
                    searchableContent: `${loc.iata_code}|${loc.icao_code}|${loc.name}`,
                })) || []
            );
        },
        fileUploadUrl() {
            return this.route('attachments.store');
        },
    },

    mounted() {
        this.physicalLocations = this.transformPhysicalLocations(
            this.locations,
        );

        if (this.isUpdateForm) {
            const location = this.asset.location;
            this.form = this.$inertia.form({
                petitec_identifier: this.asset.petitec_identifier,
                serial_number: this.asset.serial_number,
                customer_tracking_number: this.asset.customer_tracking_number,
                owner: this.asset.owner ? this.asset.owner.id : null,
                operator: this.asset.operator ? this.asset.operator.id : null,
                manufacturer: this.asset.manufacturer?.id,
                maintainer: this.asset.maintainer
                    ? this.asset.maintainer.id
                    : null,
                location_id: location?.id,
                model_id: this.asset.model?.id || null,
                screening_application: this.asset.screening_application?.value,
                purchased_at: this.parseApiDate(this.asset.purchased_at),
                deployed_at: this.parseApiDate(this.asset.deployed_at),
                attachments: [],
                statusCheckCadence: this.asset.status_checks.configuration,
                testCadence: this.asset.tests.configuration,
                maintenanceCadence: this.asset.maintenances.configuration,
                critical: this.asset.critical ? 'yes' : 'no',
                warranty_start_date: this.parseApiDate(
                    this.asset.warranty?.start_date,
                ),
                warranty_end_date: this.parseApiDate(
                    this.asset.warranty?.end_date,
                ),
            });

            this.selectedPhysicalLocationId = location?.physical_location_id;
            this.selectedManufacturerId = this.asset.manufacturer.id;
            this.models = this.transformModels(this.$page.props.models.data);

            this.specificLocations = this.transformSpecificLocations(
                this.$page.props.specific_locations.data,
            );
        }
    },

    methods: {
        handleSubmit() {
            if (this.isUpdateForm) {
                this.updateAsset();
            } else {
                this.createAsset();
            }
        },
        createAsset() {
            this.form
                .transform(this.transformData)
                .post(this.route('assets.store'));
        },
        updateAsset() {
            this.form
                .transform(this.transformData)
                .put(this.route('assets.update', this.asset));
        },
        transformData(data) {
            data = Object.assign({}, data);

            return {
                ...data,
                deployed_at: this.formatAsApiDate(data.deployed_at),
                purchased_at: this.formatAsApiDate(data.purchased_at),
                warranty_start_date: this.formatAsApiDate(
                    data.warranty_start_date,
                ),
                warranty_end_date: this.formatAsApiDate(data.warranty_end_date),
                attachments: data.attachments || [],
                critical: data.critical === 'yes',
                ...this.transformCadence('statusCheckCadence', data),
                ...this.transformCadence('testCadence', data),
                ...this.transformCadence('maintenanceCadence', data),
            };
        },
        transformCadence(propertyName, data) {
            let cadence = data[propertyName];

            if (!cadence) {
                return { [propertyName]: null };
            }

            let { startDate, unit, value } = cadence;

            if (startDate instanceof DateTime) {
                startDate = startDate.toFormat('yyyy-MM-dd');
            }

            return {
                [propertyName]: {
                    unit,
                    value,
                    startDate,
                },
            };
        },
        loadSpecificLocations({ selectedSpecificLocationId } = {}) {
            this.form.location_id = null;
            this.specificLocations = [];

            if (!this.selectedPhysicalLocationId) {
                return;
            }

            this.isLoadingSpecificLocations = true;
            this.form.processing = true;

            $http
                .get(
                    this.route(
                        'airports.show',
                        this.selectedPhysicalLocationId,
                    ),
                )
                .then((resp) => {
                    this.specificLocations = this.transformSpecificLocations(
                        resp.data.specific_locations,
                    );

                    if (this.specificLocations.length === 1) {
                        selectedSpecificLocationId =
                            this.specificLocations[0].value;
                    }
                    this.form.location_id = selectedSpecificLocationId;
                })
                .finally(() => {
                    this.form.processing = false;
                    this.isLoadingSpecificLocations = false;
                });
        },
        loadModels({ selectedModelId } = {}) {
            this.models = [];
            this.form.model_id = null;

            if (!this.form.manufacturer) {
                return;
            }

            this.form.processing = true;
            this.isLoadingModels = true;

            $http
                .get(
                    this.route('models.index', {
                        manufacturer: this.form.manufacturer,
                    }),
                )
                .then((resp) => {
                    this.models = this.transformModels(resp.data.data);

                    if (this.models.length === 1) {
                        this.form.model_id = this.models[0].value;
                    } else {
                        this.form.model_id = selectedModelId;
                    }
                })
                .finally(() => {
                    this.form.processing = false;
                    this.isLoadingModels = false;
                });
        },
        getCancelRoute() {
            return this.isUpdateForm
                ? this.route('assets.show', this.asset.id)
                : this.route('assets.index');
        },
        handleQrCodeSuccess(code) {
            this.form.petitec_identifier = code.uuid;

            setTimeout(() => {
                this.isQrCodeModalVisible = false;
            }, 300);
        },
        addNewModel() {
            if (!!this.selectedManufacturerId) {
                this.isCreateModelModalVisible = true;
            }
        },
        handleCreateSpecificLocationSuccess(data) {
            this.loadSpecificLocations({
                selectedSpecificLocationId: data.id,
            });
        },
        handleCreateSpecificLocationCancel() {
            this.isCreateSpecificLocationModalVisible = false;
        },
        handleCreateModelSuccess(data) {
            this.loadModels({ selectedModelId: data.id });

            this.isCreateModelModalVisible = false;
        },
        handlePhysicalLocationSelection(physicalLocationId) {
            this.selectedPhysicalLocationId = physicalLocationId;
            this.loadSpecificLocations();
        },
        transformSpecificLocations(locations) {
            return locations.map((location) => ({
                value: location.id,
                label: location.name,
            }));
        },
        transformModels(models) {
            return models.map((model) => ({
                value: model.id,
                label: model.designation,
                type: model.type,
            }));
        },
        transformPhysicalLocations(locations) {
            return locations.map((location) => ({
                value: location.id,
                label: [location.icao_code || location.iata_code, location.name]
                    .filter((el) => el)
                    .join(' - '),
            }));
        },
        openPetitecQrCodeModal() {
            eventBus.$emit(events.openQrCodeScanner, {
                type: PetiteCIdScannerType.scanId,
                onSuccessfulScan: (code, modal) => {
                    this.form.petitec_identifier = code;
                    modal.close();
                },
            });
        },
        createNewSpecificLocation(physicalLocationId) {
            eventBus.$emit(events.openCreateSpecificLocationModal, {
                physicalLocationId,
            });
        },
    },
};
</script>
