<template>
    <c-sidebar
        :class="{ show: sidebar.visible }"
        :unfoldable="sidebar.unfoldable"
        :visible="sidebar.visible"
        position="fixed"
        @visible-change="(value) => sidebar.toggleVisible(value)"
    >
        <slot />
    </c-sidebar>
</template>
<script setup>
import { CSidebar } from '@coreui/vue/dist/esm/components/sidebar/index.js';
import { useSidebarStore } from '@/stores/sidebar';

const sidebar = useSidebarStore();

const props = defineProps(['minimize']);
</script>
