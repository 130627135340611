<template>
    <form-group
        :horizontal="horizontal"
        :inline="inline"
        :label="label"
        :label-classes="[{ required }]"
        :group-classes="formGroupClasses"
    >
        <div v-if="!readonly">
            <dropdown
                :class="{
                    'is-invalid': !isValid,
                    filterable: this.enableSearch,
                    'dropdown-inline': inline,
                }"
                :disabled="disabled"
                :visible="show"
                :toggler-classes="addTogglerClasses"
                :toggler-text="selectedValue"
                class="form-control dropdown border-0 p-0"
                color="secondary"
                auto-close="outside"
                @update:show="handleDropdownVisibilityChange"
            >
                <template #toggler>
                    <slot
                        :disabled="disabled"
                        :placeholder="placeholder"
                        :props="selectedItem"
                        name="toggler-content"
                    >
                        <div
                            v-if="disabled"
                            style="
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                z-index: 1;
                            "
                            @click.prevent=""
                        />
                        <div v-html="selectedValue" class="w-100" />
                    </slot>
                </template>
                <div ref="listWrapper" class="dropdown-list p-0">
                    <div
                        v-if="enableSearch"
                        class="dropdown-list-filter p-2"
                        style="z-index: 2"
                    >
                        <div>
                            <div
                                class="input-group filter-search w-100"
                                style="min-width: 200px"
                            >
                                <input
                                    ref="listFilter"
                                    v-model="searchString"
                                    :placeholder="searchPlaceholder"
                                    autofocus
                                    class="form-control"
                                    style="height: 2rem"
                                    @focus="$refs.listFilter?.select()"
                                    @input="$nextTick(() => onScroll())"
                                    @keydown.enter="handleEnterKey($event)"
                                />

                                <span class="input-group-text"><Search /></span>
                            </div>
                            <div
                                v-show="isTopShadowVisible"
                                class="sh-b"
                                style="
                                    height: 5px;
                                    position: absolute;
                                    bottom: 0;
                                    left: 8px;
                                    right: 8px;
                                "
                            />
                        </div>
                    </div>
                    <dropdown-item
                        v-if="!required && resettable"
                        :class="{
                            'pl-5': hasGroups,
                        }"
                        class="option-reset"
                        @click.native="selectValue(defaultValue)"
                        >{{ __('general.dropdown.reset.label') }}
                    </dropdown-item>
                    <div v-for="group in filteredOptions">
                        <div
                            v-if="group.group !== 'default'"
                            class="px-2 bg-white"
                            style="position: sticky; top: 48px; z-index: 1"
                        >
                            <small class="text-muted text-uppercase">{{
                                group.group
                            }}</small>
                        </div>
                        <dropdown-item
                            v-for="option in group.data"
                            :key="option.value"
                            :class="{
                                selected: option.value === modelValue,
                                'pl-4': hasGroups,
                            }"
                            @click="selectValue(option.value)"
                        >
                            <slot :option="option" name="item-label">
                                <div v-html="option.label" />
                            </slot>
                        </dropdown-item>
                    </div>
                        <dropdown-item
                            v-if="!filteredOptions || !filteredOptions.length"
                            disabled
                        >
                            <slot name="empty"
                                >{{ __('general.selection.options.empty') }}
                            </slot>
                        </dropdown-item>
                </div>
                <div v-if="$slots.actions" class="dropdown-actions p-0">
                    <dropdown-divider class="m-0" />
                    <slot name="actions" />
                </div>
            </dropdown>
            <slot name="description">
                <div v-if="description">
                    <small
                        class="form-text text-muted w-100"
                        v-text="description"
                    />
                </div>
            </slot>
            <div
                v-if="invalidFeedback"
                class="invalid-feedback"
                v-text="invalidFeedback"
            ></div>
        </div>
        <div v-else :class="horizontalFieldClass">
            <slot :item="selectedItem" name="readonly">
                <div v-html="selectedValue" />
            </slot>
        </div>
    </form-group>
</template>
<script>
import JetInput from '@/Jetstream/Input.vue';
import Search from '../../icons/Search.vue';
import Dropdown from '@/Components/Dropdown.vue';
import DropdownItem from '@/Components/DropdownItem.vue';
import DropdownDivider from '@/Components/DropdownDivider.vue';
import FormGroup from '@/Components/FormGroup.vue';

export default {
    components: {
        FormGroup,
        DropdownDivider,
        DropdownItem,
        Dropdown,
        Search,
        JetInput,
    },
    emits: ['update:modelValue'],
    props: {
        label: String,
        options: Array,
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        modelValue: String,
        horizontal: {
            default: true,
        },
        invalidFeedback: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        disabled: Boolean,
        enableSearch: {
            type: Boolean,
            default: false,
        },
        defaultValue: {
            type: String,
            default: null,
        },
        addTogglerClasses: {
            type: String,
            default: '',
        },
        resettable: {
            type: Boolean,
            default: true,
        },
        searchPlaceholder: {
            type: String,
            default: '',
        },
        inline: {
            type: Boolean,
            default: false,
        },
        formGroupClasses: {
            type: [String, Object, Array],
        }
    },

    data() {
        return {
            searchString: '',
            show: false,
            isTopShadowVisible: false,
        };
    },

    computed: {
        hasGroups() {
            return (
                this.options.length && this.options[0].hasOwnProperty('group')
            );
        },
        groupedOptions() {
            let groups = [];

            this.options.forEach((option) => {
                const groupName = option.group ?? 'default';
                let data = option.data ?? [option];
                let groupContainer = groups.find((g) => g.group === groupName);

                if (groupContainer) {
                    groupContainer.data.push(...data);
                } else {
                    groups.push({ group: groupName, data: data });
                }
            });

            return groups;
        },
        filteredOptions() {
            if (this.enableSearch && this.searchString) {
                return this.groupedOptions
                    .map((group) => ({
                        group: group.group,
                        data: group.data.filter((item) =>
                            this.strip(item.searchableContent || item.label)
                                .toLowerCase()
                                .includes(this.searchString.toLowerCase()),
                        ),
                    }))
                    .filter((group) => group.data.length > 0);
            }
            return this.groupedOptions.filter((group) => group.data.length > 0);
        },
        isValid() {
            return this.invalidFeedback === '';
        },
        selectedValue() {
            return this.selectedItem?.label ?? this.placeholder;
        },
        selectedItem() {
            let item = null;
            this.groupedOptions.find((group) => {
                item = group.data.find((el) => el.value === this.modelValue);
                return item ?? false;
            });
            return item;
        },
        horizontalLabelClass() {
            if (this.horizontal === true) {
                return 'col-sm-3';
            }

            if (this.horizontal && this.horizontal.label) {
                return this.horizontal.label;
            }

            return '';
        },
        horizontalFieldClass() {
            if (this.horizontal === true) {
                return 'col-sm-9';
            }

            if (this.horizontal && this.horizontal.input) {
                return this.horizontal.input;
            }

            return '';
        },
    },

    watch: {
        show(value) {
            if (value) {
                this.$nextTick(() => {
                    this.$refs.listFilter?.focus();
                    this.$refs.listWrapper.scrollTop = 0;
                    this.onScroll();
                });
            }
        },
    },

    methods: {
        handleDropdownVisibilityChange(isShown) {
            this.show = isShown

            if (!this.enableSearch) {
                return true;
            }

            if (isShown) {
                this.$nextTick(() => {
                    this.$refs.listFilter.focus();
                });
            } else {
                this.searchString = '';
            }
        },
        handleEnterKey(event) {
            event.preventDefault();

            if (
                this.filteredOptions.length === 1 &&
                this.filteredOptions.first().data.length === 1
            ) {
                this.$emit(
                    'update:modelValue',
                    this.filteredOptions.first().data.first().value,
                );
                this.show = false;
            }
        },
        strip(html) {
            const doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || '';
        },
        onScroll() {
            this.isTopShadowVisible =
                this.$refs.listWrapper.parentElement.scrollTop > 0;
        },
        selectValue(value) {
            this.show = false;
            this.$nextTick(() => {
                this.$emit('update:modelValue', value);
            })
        }
    },

    mounted() {
        if (
            this.required &&
            this.filteredOptions.length === 1 &&
            this.filteredOptions.first().data.length === 1
        ) {
            this.$emit(
                'update:modelValue',
                this.filteredOptions.first().data.first().value,
            );
        }

        this.$refs.listWrapper?.parentElement.addEventListener(
            'scroll',
            this.onScroll,
        );
    },

    beforeDestroy() {
        this.$refs.listWrapper?.parentElement.removeEventListener(
            'scroll',
            this.onScroll,
        );
    },
};
</script>
<style scoped>
.form-control.dropdown {
    padding: 0;
}

:deep(.dropdown-toggle) {
    width: 100%;
    text-align: left;
    border: 1px solid #e4e7ea;
    background: #fff !important;
    border-radius: 4px;
    box-shadow: none !important;
    color: #5c6873 !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

:deep(.is-invalid .dropdown-toggle),
:deep(.is-invalid .dropdown-toggle.disabled) {
    border-color: #ff174d;
    color: #4f5d73;
}

:deep(.dropdown-toggle.disabled) {
    cursor: default !important;
    background: #ecedef !important;
}

:deep(.dropdown-menu) {
    min-width: 100%;
    margin: 5px 0 !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    max-height: 20em;
    max-width: 100%;
    overflow: scroll;
    padding-top: 0;
    padding-bottom: 0;
}

:deep(.dropdown-list) {
    padding-bottom: 8px;
}

:deep(.dropdown-menu:hover .selected) {
    background: #fff;
    box-shadow: none;
}

:deep(.dropdown-item) {
    position: relative;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: default;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}

:deep(.dropdown-item > div) {
    text-overflow: ellipsis;
    overflow: hidden;
}

:deep(.dropdown-item.selected),
:deep(.dropdown-item.selected:hover),
:deep(.dropdown-item.selected:focus),
:deep(.dropdown-item:hover),
:deep(.dropdown-item:focus) {
    background: #17ffc6;
    cursor: pointer;
}

:deep(.dropdown-divider){
    margin: 0 0 0.5rem;
}

:deep(.dropdown-actions){
    background: #efefef;
    padding-bottom: 5px;
    position: sticky;
    bottom: -1px;
}

:deep(.dropdown-actions .dropdown-item){
    color: #666;
    cursor: pointer;
}

:deep(.dropdown-actions .dropdown-item:hover),
:deep(.dropdown-actions .dropdown-item:focus) {
    background: none;
    box-shadow: none;
    color: #3e56c6;
}
</style>
