<template>
    <component :is="activityTypeWrapper" :item="item" style="line-height: 1.1"/>
</template>
<script>
import DefaultActivityType from './ActivityTypes/DefaultActivityType.vue';
import AssetOperabilityChanged from '@/Components/ActivityLog/ActivityTypes/AssetOperabilityChanged.vue';
import WorkOrderEntry from '@/Components/ActivityLog/ActivityTypes/WorkOrderEntry.vue';
import PerformanceDataEntry from '@/Components/ActivityLog/ActivityTypes/PerformanceDataEntry.vue';
import AssetDeleted from '@/Components/ActivityLog/ActivityTypes/AssetDeleted.vue';
import Stakeholder from '@/Components/ActivityLog/ActivityTypes/Stakeholder.vue';
import AssetStakeholdersChanged from '@/Components/ActivityLog/ActivityTypes/AssetStakeholdersChanged.vue';

export default {
    name: 'Activity',
    props: {
        item: {},
    },
    components: {
        default: DefaultActivityType,
        'asset.operability.changed': AssetOperabilityChanged,
        'asset.deleted': AssetDeleted,
        owner: Stakeholder,
        maintainer: Stakeholder,
        operator: Stakeholder,
        asset_status_check: WorkOrderEntry,
        workorder: WorkOrderEntry,
        test: WorkOrderEntry,
        'performance-data': PerformanceDataEntry,
        'asset.stakeholders.changed': AssetStakeholdersChanged,
    },
    computed: {
        activityTypeWrapper() {
            // Try exact match
            if (this.$options.components[this.item.event]) {
                return this.item.event;
            }

            // Use wildcard event
            let prefix = this.item.event.split('.')[0];
            if (this.$options.components[prefix]) {
                return prefix;
            }

            return 'default';
        },
    },
};
</script>
