<template>
    <div class="overflow-auto">
        <table class="table table-sm table-unstyled">
            <tbody>
            <tr>
                <th>Report:</th>
                <td class="px-5 text-nowrap">
                    <span v-if="report.type === 'daily'">Daily</span>
                    <span v-if="report.type === 'weekly'">Weekly</span>
                </td>
            </tr>
            <tr v-if="report.type === 'daily'">
                <th class="text-nowrap">Report Date:</th>
                <td class="px-5 text-nowrap">
                    {{
                        $filters.fullDate((report.reportedAt || report.endDate), currentLocale)
                    }}
                </td>
            </tr>
            <tr v-if="report.type === 'daily'">
                <th class="text-nowrap">Report Time:</th>
                <td class="px-5 text-nowrap">
                    <a
                        v-c-popover="{
                                placement: 'bottom',
                                header: '',
                                content:
                                    'Point in time the data was captured at each location.',
                            }"
                        class="popover-no-header"
                        href="javascript:void(0)"
                    ><span v-if="report.reportedAt">{{
                            $filters.time(report.reportedAt, currentLocale)
                        }}</span
                    ><span v-else>{{
                            __('general.not_available')
                        }}</span></a
                    >
                </td>
            </tr>
            <tr v-if="report.type === 'daily'">
                <th class="text-nowrap"># of Inoperable Assets:</th>
                <td class="px-5 text-nowrap">
                        <span v-if="report.inoperableAssets?.data.length > 0">
                            {{ report.inoperableAssets.meta.total || 0 }}
                            of {{ totalAssetsCount }}
                        </span>
                    <span v-else>{{ __('general.not_available') }}</span>
                </td>
            </tr>
            <tr v-if="report.type === 'weekly'">
                <th class="text-nowrap">Timeframe:</th>
                <td class="px-5 text-nowrap">
                    {{ $filters.fullDate(report.startDate, currentLocale) }}
                    -
                    {{ $filters.fullDate(report.endDate, currentLocale) }}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    name: 'report-meta-info',
    props: {
        report: {},
        totalAssetsCount: {},
    },
};
</script>
