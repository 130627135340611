import {DateTime} from 'luxon';

export default function (value, format, locale, timezone) {
    if (!value) return '';

    if (!locale) {
        throw "Parameter 'locale' is missing!";
    }

    if (!format) {
        throw "Parameter 'format' is missing!";
    }

    return DateTime.fromISO(value).setLocale(locale).toLocaleString(format);
}
